import {
  Box,
  Button,
  Image,
  Input,
  Select,
  Text,
  Textarea,
  HStack,
  Checkbox,
} from "@chakra-ui/react";
import React, { useRef, useState } from "react";
import Layout from "../../components/Layout";
import { rowes, card_7, profile, profilee } from "../../assets/images";
import { icon_15 } from "../../assets/icons";
import { FormControl, FormLabel } from "@chakra-ui/react";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { MdDeleteForever } from "react-icons/md";
import { FiUpload } from "react-icons/fi";
import {
  deleteUserBusinessImg,
  getCategory,
  getSubCategory,
  myBussinessDetails,
  singleFileUpload,
  updateBusinessDetails,
} from "../../api/user";
import Header from "../../components/Header";
import { toast } from "react-toastify";
import moment from "moment";
import GoogleMapReact from "google-map-react";
import GooglePlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-google-places-autocomplete";
import { DAYS, OFFPEAK, OPENINGTIME } from "../../constants";
import DaysTime from "../../components/DaysTime";
import OffPeakTime from "../../components/OffPeakTime";
import OpeningDays from "../../components/OpeningDays";
import Navbar from "../../components/Navbar";
import { FaMapMarkerAlt } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

const schema = yup
  .object({
    phoneNumber: yup
      .string()
      .matches(/^(?!0000)\d{10,10}$/, {
        message: "Enter valid format",
        excludeEmptyString: true,
      }),
  })
  .required();

const Index = () => {
  const navigate = useNavigate();
  const { subCategories: pre } = useSelector((state) => state.categories);
  const [loading, setLoading] = useState(false);
  const [gallery, setGallery] = useState([]);
  const [profileImage, setprofileImage] = useState("");
  const [value, setValue] = useState(null);
  const profileImageRef = useRef(null);
  const imageRef = useRef(null);
  const [address, setAddress] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);
  const [preview, setPreview] = useState(null);
  const [details, setDetails] = useState([]);
  const [peak, setPeak] = useState(OFFPEAK);
  const [users, setUsers] = useState(DAYS);
  const [opening, setOpening] = useState(OPENINGTIME);
  const [isOffPeakParentChecked, setIsOffPeakParentChecked] = useState(false);
  const [isOpeningParentChecked, setOpeningParentChecked] = useState(false);
  const [checkedDays, setCheckedDays] = useState([]);
  const [openingCheckedDays, setOpeningCheckedDays] = useState([]);
  const [offPeakCheckedDays, setoffPeakCheckedDays] = useState([]);
  const [categories, setCategories] = useState([]);
  const [subCategories, setSubCategories] = useState([]);

  const [businessName, setBusinessName] = useState("");
  const [joinedDate, setJoinedDate] = useState(moment(new Date()).format("LL"));
  const { seller } = useSelector((state) => state.seller);
  const [peakDays, setPeakDays] = useState([]);
  const [offPeakOpeningDays, setOffPeakOpeningDays] = useState([]);
  const [openingDays, setOpeningDays] = useState([]);
  const [isParentChecked, setIsParentChecked] = useState(false);
  const [location, setLocation] = useState({
    lat: "",
    lng: "",
  });

  const [position, setPosition] = useState({
    lat: 41,
    lng: -71,
  });

  const {
    register,
    handleSubmit,
    reset,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });
  const dispatch = useDispatch();
  const selectedSubCategory = watch("category");

  const handleSubCategoryChange = (value) => {
    dispatch(getSubCategory(value)).then((res) => {
      setSubCategories(res.data.categories[0].subCategory);
    });
    // console.log("SubCategory changed to:", value);
    // Add your custom logic here
  };

  useEffect(() => {
    if (selectedSubCategory) {
      handleSubCategoryChange(selectedSubCategory);
    }
  }, [selectedSubCategory]);

  const handleStartTime = (e, user) => {
    const newUsers = peakDays.length > 0 ? [...peakDays] : [...users];
    const filteredDay = newUsers.map((item) =>
      item.day === user.day ? { ...item, startTime: e } : item
    );
    setPeakDays(filteredDay);
  };

  const handleOpeningStartTime = (e, user) => {
    const newUsers = openingDays.length > 0 ? [...openingDays] : [...opening];
    const filteredDay = newUsers.map((item) =>
      item.day === user.day ? { ...item, startTime: e } : item
    );
    setOpeningDays(filteredDay);
  };

  const handleOffPeakStartTime = (e, user) => {
    const newUsers =
      offPeakOpeningDays.length > 0 ? [...offPeakOpeningDays] : [...peak];
    const filteredDay = newUsers.map((item) =>
      item.day === user.day ? { ...item, startTime: e } : item
    );
    setOffPeakOpeningDays(filteredDay);
  };

  const handleEndTime = (e, user) => {
    const newUsers = peakDays.length > 0 ? [...peakDays] : [...users];
    const filteredDay = newUsers.map((item) =>
      item.day === user.day ? { ...item, endTime: e } : item
    );
    setPeakDays(filteredDay);
  };
  const handleOpeningEndTime = (e, user) => {
    const newUsers = openingDays.length > 0 ? [...openingDays] : [...opening];
    const filteredDay = newUsers.map((item) =>
      item.day === user.day ? { ...item, endTime: e } : item
    );
    setOpeningDays(filteredDay);
  };
  const handleOffPeakEndTime = (e, user) => {
    const newUsers =
      offPeakOpeningDays.length > 0 ? [...offPeakOpeningDays] : [...peak];
    const filteredDay = newUsers.map((item) =>
      item.day === user.day ? { ...item, endTime: e } : item
    );
    setOffPeakOpeningDays(filteredDay);
  };
  const changeCheckboxStatus = (e, id, day) => {
    const { checked } = e.target;

    if (id === "p1") {
      // Handle "Select All Days" checkbox
      const updatedUsers = users.map((user) => ({
        ...user,
        isChecked: checked,
      }));
      setIsParentChecked(checked);
      setUsers(updatedUsers);

      // Update checked days state
      setCheckedDays(checked ? [...updatedUsers] : []);
    } else {
      // Handle single day checkbox
      const updatedUsers = users.map((user) => {
        if (user._id === day._id) {
          const updatedItem = { ...user, isChecked: checked };
          if (checked) {
            setCheckedDays((prev) => [...prev, updatedItem]);
          } else {
            setCheckedDays((prev) =>
              prev.filter((item) => item._id !== user._id)
            );
          }
          return updatedItem;
        }
        return user;
      });
      setUsers(updatedUsers);

      // Check if all days are selected to update parent checkbox
      const isAllChildsChecked = updatedUsers.every((user) => user.isChecked);
      setIsParentChecked(isAllChildsChecked);
    }
  };

  const changeOffPeakCheckboxStatus = (e, id, day) => {
    const { checked } = e.target;

    if (id === "p2") {
      const updatedUsers = peak.map((user) => ({
        ...user,
        isChecked: checked,
      }));
      setIsOffPeakParentChecked(checked);
      setPeak(updatedUsers);
      setoffPeakCheckedDays(checked ? [...updatedUsers] : []);
    } else {
      const updatedUsers = peak.map((user) => {
        if (user._id === day._id) {
          const updatedItem = { ...user, isChecked: checked };
          if (checked) {
            setoffPeakCheckedDays((prev) => [...prev, updatedItem]);
          } else {
            setoffPeakCheckedDays((prev) =>
              prev.filter((item) => item._id !== user._id)
            );
          }
          return updatedItem;
        }
        return user;
      });

      setPeak(updatedUsers);
      const isAllChildsChecked = updatedUsers.every((user) => user.isChecked);
      setIsOffPeakParentChecked(isAllChildsChecked);
    }
  };

  const changeCheckboxStatusOpening = (e, id, day) => {
    const myUsers = [...opening];
    const { checked } = e.target;
    myUsers.map((user) => {
      if (id === "p3") {
        setOpeningParentChecked(checked);
        user.isChecked = checked;
      } else {
        if (user._id === day._id) {
          user.isChecked = checked;
          if (user.startTime !== "" && user.endTime !== "") {
          }
          if (checked) {
            setOpeningCheckedDays([...openingCheckedDays, user]);
          } else {
            const removeUser = openingCheckedDays.filter(
              (item) => item !== user
            );
            setOpeningCheckedDays(removeUser);
          }
        }
        const isAllChildsChecked = myUsers.every(
          (user) => user.isChecked === true
        );
        if (isAllChildsChecked) {
          setOpeningParentChecked(checked);
        } else {
          setOpeningParentChecked(false);
        }
      }
      return user;
    });

    setOpening([...myUsers]);
  };

  const profileImageHandleChange = (e) => {
    const profileImg = e.target.files[0];
    if (profileImg) {
      dispatch(singleFileUpload(profileImg))
        .then((res) => setprofileImage(res.data.image))
        .catch((error) => console.log(error));
    }
    setImagePreview(URL.createObjectURL(profileImg));
  };

  const profileOnClick = (e) => {
    profileImageRef.current.click();

  };

  const deleteBussinessImg = (i) => {
      let updateImage = [...gallery];
       updateImage = updateImage.filter((item,index)=> index !== i);
       setGallery(updateImage);
     dispatch(deleteUserBusinessImg(i)).then((res)=>{
      console.log(res)
      dispatch(myBussinessDetails())
     }).catch((err)=>{
      console.log(err)
     })
    // setGallery((prevGallery) =>
    //   prevGallery.filter((_img, index) => index !== i)
    // );
  };

  const handleChange = (e) => {
    const img = e.target.files[0];
    if (img) {
      dispatch(singleFileUpload(img))
        .then((res) => setGallery([...gallery, res.data.image]))
        .catch((error) => console.log(error));
    }
    setPreview(URL.createObjectURL(img));
  };

  const handleButtonClick = () => {
  
    imageRef.current.click();
  };



  useEffect(() => {
    dispatch(getCategory()).then((res) => {
      setCategories(res.data.categories);
    });
  }, []);

  useEffect(() => {
    dispatch(myBussinessDetails()).then((res) => {
      setprofileImage(res?.data?.user?.profileImage);
      setGallery(res?.data?.user?.gallery);
      setBusinessName(res?.data?.user?.name?.businessName);
      setJoinedDate(moment(res?.data?.user?.createdAt).format("LL"));
      setAddress(res?.data?.user?.address?.address);
      setLocation({
        lat: res?.data?.user?.address?.latitude,
        lng: res?.data?.user?.address?.longitude,
      });
      setDetails({
        email: res?.data?.user?.email,
        businessName: res?.data?.user?.name?.businessName,
        contactPerson: res?.data?.user?.name?.contactPerson || "Anonymous",
        category: res?.data?.user?.category?.id,
        subCategory: res?.data?.user?.subCategory?.id,
        bio: res?.data?.user?.bio,
        phoneNumber: res?.data?.user?.phone?.phoneNumber || "9084632266",
        peakStartTime: res?.data?.user?.peak?.startTime,
        peakEndTime: res?.data?.user?.peak?.endTime,
        OffPeakStartTime: res?.data?.user?.offpeak?.startTime,
        OffPeakEndTime: res?.data?.user?.offpeak?.endTime,
        profileImage: res?.data?.user?.profileImage,
      });

      if (res?.data?.user.peak?.length > 0) {
        setUsers(res?.data?.user.peak);
        setPeakDays(res?.data?.user.peak);
        const myUsers = [...res?.data?.user.peak];
        const checked = true;
        const filteredDays = myUsers.filter((day) => day.startTime !== "");

        // myUsers.map((user) => {
        //   if (filteredDays.length === 7) {
        //     setCheckedDays(checked);
        //     user.isChecked = checked;
        //   } else {
        //     if (user.startTime !== "" && user.endTime !== "") {
        //       user.isChecked = checked;
        //     }
        //   }
        //   return user;
        // });
        const updatedUsers = myUsers.map((user) => {
          const updatedUser = { ...user };

          if (filteredDays.length === 7) {
            setCheckedDays(checked);
            updatedUser.isChecked = checked; // Modify the shallow copy
          } else {
            if (updatedUser.startTime !== "" && updatedUser.endTime !== "") {
              updatedUser.isChecked = checked;
            }
          }
          return updatedUser; // Return the modified copy
        });
        setUsers(updatedUsers);
      }
      if (res?.data?.user.offpeak?.length > 0) {
        setPeak(res?.data?.user.offpeak);
        setOffPeakOpeningDays(res?.data?.user.offpeak);
        const myUsers = [...res?.data?.user.offpeak];
        const checked = true;
        const filteredDays = myUsers.filter((day) => day.startTime !== "");
        const updateData = myUsers.map((user) => {
          const updatedUser = { ...user };
          if (filteredDays.length === 7) {
            setoffPeakCheckedDays(checked);
            updatedUser.isChecked = checked;
          } else {
            if (updatedUser.startTime !== "" && updatedUser.endTime !== "") {
              updatedUser.isChecked = checked;
            }
          }
          return updatedUser;
        });
        setPeak(updateData);
      }
      if (res?.data?.user.openingDays?.length > 0) {
        setOpening(res?.data?.user.openingDays);
        setOpeningDays(res?.data?.user.openingDays);
        const myUsers = [...res?.data?.user.openingDays];
        const checked = true;
        const filteredDays = myUsers.filter((day) => day.startTime !== "");
        myUsers.map((user) => {
          if (filteredDays.length === 7) {
            setOpeningParentChecked(checked);
            user.isChecked = checked;
          } else {
            if (user.startTime !== "" && user.endTime !== "") {
              user.isChecked = checked;
            }
          }
          return user;
        });
      }
    });
  }, []);

  useEffect(() => {
    reset(details);
  }, [details]);

  const CustomDropdownIndicator = () => {
    return (
      <FaMapMarkerAlt
        onClick={fetchCurrentLocation}
        style={{ color: "grey", marginRight: "10px", cursor: "pointer" }}
      />
    );
  };

  const fetchCurrentLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          // Use reverse geocoding to get the address from the coordinates
          const geocoder = new window.google.maps.Geocoder();
          const latlng = { lat: latitude, lng: longitude };

          geocoder.geocode({ location: latlng }, (results, status) => {
            if (status === "OK") {
              if (results[0]) {
                setValue({
                  label: results[0].formatted_address,
                  value: results[0].place_id,
                });
              } else {
                console.log("No results found");
              }
            } else {
              console.log("Geocoder failed due to: " + status);
            }
          });
        },
        (error) => {
          console.log("Error fetching the current location: ", error);
        }
      );
    } else {
      console.log("Geolocation is not supported by this browser.");
    }
  };

  useEffect(() => {
    if (value) {
      setAddress(value.label);
      geocodeByAddress(value.label)
        .then((results) => getLatLng(results[0]))
        .then(({ lat, lng }) =>
          setLocation({
            lat,
            lng,
          })
        );
    }
  }, [value]);

  const newCat = categories?.find((item) => item?._id === seller?.category?.id);
  const newSubCat = newCat?.subCategory?.find(
    (item) => item?._id === seller?.subCategory?.id
  );

  const updateDetails = (data) => {
    const category = categories.filter((item) => item._id === data.category);
    const subcategory = category[0]?.subCategory?.filter(
      (item) => item._id === data.subCategory
    );

    const apiData = {
      businessName: data.businessName,
      primaryContact: data.primaryContact,
      email: data.email,
      address,
      phoneNumber: data.phoneNumber,
      bio: data.bio,
      gallery,
      profileImage,
      category: {
        id: category[0]._id,
        name: category[0].mainCategory,
      },
      subCategory: {
        id: subcategory[0]._id,
        name: subcategory[0].name,
      },
      latitude: location.lat,
      longitude: location.lng,
      peak: peakDays,
      offpeak: offPeakOpeningDays,
      openingDays,
    };
    // return  console.log(apiData, "apiData");

    dispatch(updateBusinessDetails(apiData))
      .then((res) => {
        if (res.statusCode === 200) {
          toast.success(res?.message);
          setLoading(false);
          navigate("/home");
        }
      })
      .catch((err) => {
        toast.error(err?.messsage);
      });
  };

  const mapRef = useRef(null);
  function handleLoad(map) {
    mapRef.current = map;
  }

  function handleCenter() {
    if (!mapRef.current) return;

    const newPos = mapRef.current.getCenter().toJSON();
    setPosition(newPos);
  }

  useEffect(() => {
    if (seller) {
      const newPosition = {
        lat: seller?.address?.latitude,
        lng: seller?.address?.longitude,
      };
      setPosition(newPosition);
    }
    if (value) {
      setPosition(location);
    }
  }, [seller, value]);

  const Marker = ({ text, img }) => (
    <div
      style={{
        color: "red",
        // background: 'red',
        padding: "15px 10px",
        display: "inline-flex",
        textAlign: "center",
        alignItems: "center",
        justifyContent: "center",
        borderRadius: "50%",
        transform: "translate(-50%, -50%)",
        width: "40px",
        height: "40px",
      }}
    >
      {img}
    </div>
  );
  return (
    <>
      <Layout>
        <Box
          w={{ xl: "99%", lg: "98%", md: "96%" }}
          mx={{ xl: "auto", lg: "0px", md: "auto" }}
        >
          <Navbar />
          <Box
            w={{ xl: "65%", lg: "70%", md: "100%" }}
            display="flex"
            mt={5}
            justifyContent="space-between"
          >
            <Box
              display="flex"
              alignItems="center"
              w="full"
              justifyContent="space-between"
            >
              <HStack>
                <Box position="relative">
                  <Image
                    borderRadius="full"
                    src={profileImage || profilee}
                    objectFit="cover"
                    h={100}
                    w={100}
                    {...register("profileImage")}
                  />
                  <Image
                    position="absolute"
                    right={[1, 0]}
                    top={[2]}
                    src={icon_15}
                    bg="#1E0F42"
                    p="5px"
                    borderRadius="full"
                    h="25px"
                    w="25px"
                    onClick={profileOnClick}
                  />
                  <Input
                    type="file"
                    display="none"
                    accept="image/png, image/gif, image/jpeg"
                    ref={profileImageRef}
                    onChange={profileImageHandleChange}
                  ></Input>
                </Box>
                <Box ml={4}>
                  <HStack>
                    <Text
                      fontSize={{ xl: 26, lg: 23, md: 22 }}
                      fontWeight={600}
                      color="#1E0F42"
                    >
                      {businessName}
                    </Text>
                    <Text
                      fontWeight="bold"
                      color="#FF1A57"
                      textTransform="capitalize"
                    >
                      {seller?.accountStatus}
                    </Text>
                  </HStack>
                  <Text
                    fontSize={{ xl: 20, md: 18 }}
                    fontWeight="600"
                    color="#72992E"
                    fontFamily="Rubik"
                  >
                    {seller?.role}
                  </Text>
                  <Text fontSize={14} fontWeight="400" color="#3B3538">
                    Joined in {joinedDate}
                  </Text>
                </Box>
              </HStack>
            </Box>
          </Box>
          <Box>
            <Box w={{ xl: "65%", lg: "70%", md: "100%" }} mt="20px">
              <FormLabel color="#6B6162" mb="0.5" fontSize="14px">
                Business Name
              </FormLabel>
              <Input
                bg="white"
                shadow="lg"
                type="text"
                placeholder="Rowe's Bakery"
                fontSize="14px"
                color="#4B4447"
                {...register("businessName")}
              />
            </Box>
          </Box>
          <Box>
            <Box w={{ xl: "65%", lg: "80%", md: "100%" }} mt="15px" mb="50px">
              <FormControl
                display="flex"
                direction="row"
                mt="12px"
                justifyContent="space-between"
                gap={6}
              >
                <Box w={{ xl: "48%", lg: "48%", md: "48%" }}>
                  <FormLabel color="#6B6162" mb="0.5" fontSize="14px">
                    Contact Email
                  </FormLabel>
                  <Input
                    type="email"
                    placeholder="hedzwills@gmail.com"
                    fontSize="14px"
                    color="#4B4447"
                    bg="white"
                    shadow="lg"
                    {...register("email")}
                  />
                </Box>
                <Box w={{ xl: "48%", lg: "48%", md: "48%" }}>
                  <FormLabel color="#6B6162" mb="0.5" fontSize="14px">
                    Category
                  </FormLabel>
                  <Select
                    bg="white"
                    shadow="lg"
                    placeholder="Select Category"
                    fontSize="14px"
                    color="#4B4447"
                    {...register("category")}
                  >
                    {categories.map((value) => (
                      <option key={value._id} value={value._id}>
                        {value.mainCategory}
                      </option>
                    ))}
                  </Select>
                </Box>
                <Box w={{ xl: "48%", lg: "48%", md: "48%" }}>
                  <FormLabel color="#6B6162" mb="0.5" fontSize="14px">
                    Sub Category
                  </FormLabel>
                  <Select
                    bg="white"
                    shadow="lg"
                    placeholder="Select Sub_Category"
                    fontSize="14px"
                    color="#4B4447"
                    // disabled
                    {...register("subCategory")}
                  >
                    {subCategories &&
                      subCategories.map((value) => (
                        <option key={value._id} value={value?._id}>
                          {value?.name}
                        </option>
                      ))}
                  </Select>
                </Box>
              </FormControl>
              <FormControl
                display="flex"
                direction="row"
                justifyContent="space-between"
                mt="15px"
              >
                <Box w={{ xl: "48%", lg: "48%", md: "48%" }}>
                  <FormLabel color="#6B6162" mb="0.5" fontSize="14px">
                    Contact Person
                  </FormLabel>
                  <Input
                    type="text"
                    placeholder="Hedz"
                    fontSize="14px"
                    color="#4B4447"
                    bg="white"
                    shadow="lg"
                    {...register("contactPerson")}
                  />
                </Box>
                <Box w={{ xl: "48%", lg: "48%", md: "48%" }}>
                  <div className="mb-lg-2 mb-1 col-sm">
                    <FormLabel
                      color="#6B6162"
                      mb="0.5"
                      fontSize="14px"
                      mt={["2", "0"]}
                    >
                      Contact Details
                    </FormLabel>
                    <div className="input-group mb-1">
                      <span className="input-group-text" id="basic-addon3">
                        +44
                      </span>
                      <input
                        type="number"
                        className="form-control"
                        placeholder="Enter your phone number"
                        {...register("phoneNumber")}
                        // onKeyDown={handleSpacebarPress}
                        onWheel={(e) => e.target.blur()}
                      />
                    </div>
                    <Text my={1} color="red" fontSize="xs">
                      {errors.phoneNumber?.message}
                    </Text>
                  </div>
                </Box>
              </FormControl>
              <FormControl
                display="flex"
                direction="row"
                justifyContent={{
                  xl: "space-between",
                  lg: "space-between",
                  md: "space-between",
                }}
                mt="15px"
              >
                <Box w={{ xl: "48%", lg: "35%", md: "33%" }}>
                  <FormLabel color="#6B6162" mb="0.5" fontSize="14px">
                    Peak
                  </FormLabel>
                  <Checkbox
                    colorScheme="red"
                    onChange={(e) => changeCheckboxStatus(e, "p1")}
                    isChecked={isParentChecked}
                  >
                    <Text fontSize="13.5px" color="#6B6162">
                      Select All Days
                    </Text>
                  </Checkbox>

                  <DaysTime
                    days={users}
                    colorScheme="red"
                    fontSize={12}
                    handleStartTime={handleStartTime}
                    handleEndTime={handleEndTime}
                    changeCheckboxStatus={changeCheckboxStatus}
                  />
                </Box>
                <Box w={{ xl: "47%", lg: "35%", md: "48%" }}>
                  <FormLabel color="#6B6162" mb="0.5" fontSize="14px">
                    Off Peak
                  </FormLabel>
                  <Checkbox
                    colorScheme="red"
                    onChange={(e) => changeOffPeakCheckboxStatus(e, "p2")}
                    isChecked={isOffPeakParentChecked}
                  >
                    <Text fontSize="13.5px" color="#6B6162">
                      Select All Days
                    </Text>
                  </Checkbox>
                  <OffPeakTime
                    days={peak}
                    colorScheme="red"
                    fontSize={12}
                    handleStartTime={handleOffPeakStartTime}
                    handleEndTime={handleOffPeakEndTime}
                    changeCheckboxStatus={changeOffPeakCheckboxStatus}
                  />
                </Box>
              </FormControl>
            </Box>
          </Box>
          <Box
            w={{ xl: "65%", lg: "70%", md: "80%" }}
            display="flex"
            alignItems="center"
            flexWrap="wrap"
            gap={"4"}
          >
            {gallery.map((item, index) => (
              <Box
                position="relative"
                w={{ xl: "30%", lg: "30%", md: "40%" }}
                // justifyContent="space-between"
                key={index}
                mt={{ "2xl": "8px", xl: "8px" }}
              >
                <Box
                  w={{ xl: "full", lg: "full", md: "full" }}
                  h={{ xl: "200px", lg: "150px", md: "120px" }}
                  borderRadius={"10px"}
                  overflow={"hidden"}
                >
                  <Image
                    src={item || card_7}
                    w={"full"}
                    h={"full"}
                    objectFit={"cover"}
                    {...register("gallery")}
                  />
                </Box>
                <Box
                  position="absolute"
                  top={{ xl: 2, lg: 2, md: 2 }}
                  right={{ "2xl": "10px", xl: 45, lg: 6, md: 16 }}
                  display={"flex"}
                  gap={"2"}
                >
                  {/* <Image
                  display={'none'}
                  src={icon_15}
                  bg="#1E0F42"
                  p="4px"
                  borderRadius="100%"
                 
                  h="25px"
                  w="25px"
                  onClick={handleButtonClick}
                /> */}

                  <MdDeleteForever
                    size={24}
                    fill="white"
                    style={{
                      backgroundColor: "#FF1A57",
                      padding: "4px",
                      borderRadius: "50%",
                    }}
                    onClick={() => deleteBussinessImg(index)}
                  />
                </Box>

                
              </Box>
            ))}
            <Input
                  type="file"
                  display="none"
                  accept="image/png, image/gif, image/jpeg"
                  ref={imageRef}
                  onChange={handleChange} 

                  
                />
            <div class="file-upload">
              <div class="image-upload-wrap" onClick={(e)=> handleButtonClick(e)}>
                <div class="drag-text">
                  <FiUpload size={40} />
                </div>
              </div>
            </div>
          </Box>
          <FormControl
            display="flex"
            direction="row"
            justifyContent="space-between"
            mt="15px"
          >
            <Box w={{ xl: "47%", lg: "35%", md: "80%" }}>
              <FormLabel color="#6B6162" fontSize="16px" mb={4}>
                Opening Days
              </FormLabel>
              <Checkbox
                colorScheme="red"
                onChange={(e) => changeCheckboxStatusOpening(e, "p3")}
                isChecked={isOpeningParentChecked}
              >
                <Text fontSize="13.5px" color="#6B6162">
                  Select All Days
                </Text>
              </Checkbox>

              <OpeningDays
                days={opening}
                colorScheme="red"
                fontSize={12}
                handleStartTime={handleOpeningStartTime}
                handleEndTime={handleOpeningEndTime}
                changeCheckboxStatus={changeCheckboxStatusOpening}
              />
            </Box>
          </FormControl>
          <Box>
            <Box w={{ xl: "65%", lg: "70%", md: "100%" }} mt="20px">
              <FormLabel fontSize="14px" color="#6B6162" fontWeight="400">
                About Seller
              </FormLabel>
              <Textarea {...register("bio")} bg="white" shadow="lg" />
            </Box>
          </Box>
          <FormLabel fontSize="14px" color="#6B6162" fontWeight="400" mt="10px">
            Add Address
          </FormLabel>
          <Box
            w={{ xl: "65%", lg: "70%", md: "100%" }}
            mt="10px"
            border="1px"
            borderColor="gray.200"
            bg="white"
            shadow="lg"
          >
            <Box w="98%" mx="auto" my={4}>
              <GooglePlacesAutocomplete
                className="form-control border_primary c_sel has-search"
                apiKey={"AIzaSyBbd_Q8kvM9rmBakgRFXTgvjYpgVhNKB-M"}
                selectProps={{
                  value,
                  placeholder: seller?.address?.address,
                  onChange: setValue,
                  components: {
                    DropdownIndicator: (props) => (
                      <CustomDropdownIndicator
                        fetchCurrentLocation={fetchCurrentLocation}
                        {...props}
                      />
                    ),
                    IndicatorSeparator: () => null,
                  },
                }}
              />
              <Box h={320} my={4}>
                <GoogleMapReact
                  bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_API }}
                  zoom={12}
                  onLoad={handleLoad}
                  onDragEnd={handleCenter}
                  center={position}
                  id="map"
                  mapContainerStyle={{
                    height: "900px",
                    width: "900px",
                  }}
                >
                  <Marker
                    lat={position.lat}
                    lng={position.lng}
                    img={
                      <FaMapMarkerAlt
                        style={{ width: "400px", height: "400px" }}
                      />
                    }
                  />
                </GoogleMapReact>
              </Box>
            </Box>
          </Box>
          <Box
            w={{ xl: "65%", lg: "70%", md: "100%" }}
            mt="20px"
            mb="50px"
            flexDirection={["column", "row"]}
          >
            <Box w={["100%", "100%"]}>
              <Button
                w="full"
                bg="#FF1A57"
                color="white"
                fontSize="14px"
                borderRadius="5px"
                isLoading={loading}
                mt="10px"
                onClick={handleSubmit(updateDetails)}
              >
                Confirm Changes
              </Button>
            </Box>
          </Box>
        </Box>
      </Layout>
    </>
  );
};
export default Index;
