import React, { useEffect, useState } from "react";
import Layout from "../../components/Layout";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useDispatch } from "react-redux";
// import { createCostumerSupport } from "../../api/user";
import { useNavigate } from "react-router-dom";
import { Image, Spinner } from "@chakra-ui/react";
import { updatedLogo } from "../../assets/icons";
import { createCostumerSupport } from "../../api/user";
import { toast } from "react-toastify";
// import { requestForToken } from "../../firebase";


const schema = yup
  .object({
    firstName: yup.string().required("First Name is Required*"),
    lastName: yup.string().required("Last Name is Required*"),
    email: yup
      .string()
      .required("Email is required*")
      .email("Enter valid Email"),
    message: yup.string().required("Message is required*"),
    phoneNo :yup.string().matches(/^(?!0000)\d{10,10}$/, {message:"Enter valid format",excludeEmptyString:true}),

  })
  .required();

const ContactUs = () => {
    const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading,setLoading] = useState(false)
 
    const {
        register,
        handleSubmit,
        formState: { errors },
      } = useForm({
        resolver: yupResolver(schema),
      });
    
  
//   requestForToken(setTokenFound, setCurrentToken);
useEffect(() => {
  // Scroll to the top of the page
  window.scrollTo(0, 0);
}, []);

  const contactUs = (data) => {

    const apiData = {
      email: data.email,
      fcmToken :"",
  firstName: data.firstName,
   lastName: data.lastName,
message: data.message,
phoneNo: data.phoneNo
    }
    setLoading(true)
    //  console.log("first",apiData)
    dispatch(createCostumerSupport(apiData))
      .then((res) => {
        if (res.statusCode === 200) {
        setLoading(false)
          toast.success(res?.message);
          navigate("/home");
        }
        // console.log(res);
      })
      .catch((err) => {
        setLoading(false)
        toast.error(err.message);
      });

    // console.log(data, "dsfrds");
  };
  const handleSpacebarPress = (event) => {
    const { value } = event.target;
    if (event.key === ' ' && value.trim() === '') {
      alert('Spaces are not allowed in this field');
      event.preventDefault(); // Prevents the space character from being added to the input
    }
  }
  return (
  
         <section className="about-content">
        <div className="container-fluid">
          <div className="row justify-content-center ">
            <div className="col-xl-5 col-lg-5 col-md-12 py-3 contact_pg">
               <div className="logo d-flex justify-content-center align-items-center mb-3  flex-column">
                    <div className="img mb-1">
                        <Image src={updatedLogo} w="170px" h="50px" /> 
                    </div>
                    <p className="mb-2">Seller Dashboard V1.0.1</p>
                </div> 
              <h3 className="fs-3 fw-bold my-2">Contact us</h3>
              <h4 className="font-18 mb-4">
                {/* Lorem ipsum dolor sit amet, consectetur adipiscing elit. Turpis
                amet lorem egestas pretium tincidunt et, pharetra, condimentum. */}
              </h4>
              <form action="" className="mb-lg-5 my-3  ">
                <div className="row">
                  <div className="mb-lg-4  mb-2 col-sm">
                    <label className="form-label">First name</label>
                    <input
                      type="text"
                      className="form-control form-control-lg"
                      placeholder="Enter your first name"
                      {...register("firstName")}
                      onKeyDown={handleSpacebarPress}
                    />
                    <span className="" style={{ color: "red", fontSize: 14 }}>
                      {errors.firstName?.message}
                    </span>
                  </div>
                  <div className="mb-lg-4  mb-3 col-sm">
                    <label className="form-label">Last name</label>
                    <input
                      type="text"
                      className="form-control form-control-lg"
                      placeholder="Enter your last name"
                      {...register("lastName")}
                      onKeyDown={handleSpacebarPress}
                    />
                    <span className="" style={{ color: "red", fontSize: 14 }}>
                      {errors.lastName?.message}
                    </span>
                  </div>
                </div>
                <div className="row">
                  <div className="mb-lg-2  mb-1 col-sm">
                    <label className="form-label">Email address</label>
                    <input
                      type="text"
                      className="form-control form-control-lg"
                      placeholder="Enter your email address"
                      {...register("email")}
                      onKeyDown={handleSpacebarPress}
                    />
                    <span className="" style={{ color: "red", fontSize: 14 }}>
                      {errors.email?.message}
                    </span>
                  </div>
                  <div className="mb-lg-2 mb-1 col-sm">
                    <label className="form-label">
                      Phone number (optional)
                    </label>
                    <div className="input-group mb-1">
                      <span className="input-group-text" id="basic-addon3">
                        +44
                      </span>
                      <input
                        type="number"
                        className="form-control form-control-lg"
                        placeholder="Enter your phone number"
                        {...register("phoneNo")}
                        onKeyDown={(e) => {
                          handleSpacebarPress(e)
                          if (!/[0-9]/.test(e.key) && e.key !== "Backspace" && e.key !== "ArrowLeft" && e.key !== "ArrowRight" && e.key !== "Tab") {
                            e.preventDefault();
                          }
                        }}
                        onWheel={(e) => e.target.blur()}
                      />
                    </div>
                    <span className="" style={{ color: "red", fontSize: 14 }}>
                        {errors.phoneNo?.message}
                      </span>
                   </div>
                </div>
                {/* <div className="mb-lg-4  mb-3 ">
                  <label className="form-label">Enquiry</label>
                  <select
                    className="form-select form-select-lg"
                    {...register("enquiry")}
                    aria-label="Default select example"
                  >
                    <option selected>Select a reason for contacting us</option>
                    <option value="One">One</option>
                    <option value="Two">Two</option>
                    <option value="Three">Three</option>
                  </select>
                  <span className="" style={{ color: "red", fontSize: 14 }}>
                    {errors.enquiry?.message}
                  </span>
                </div> */}

                <div className="mb-lg-4 mb-3">
                  <label className="form-label">Message</label>
                  <textarea
                    name=""
                    id=""
                    cols="30"
                    rows="10"
                    {...register("message")}
                    className="form-control"
                    placeholder="Enter your message..."
                    onKeyDown={handleSpacebarPress}
                  ></textarea>
                  <span className="" style={{ color: "red", fontSize: 14 }}>
                    {errors.message?.message}
                  </span>
                </div>
                <div className="form-btn mt-3">
                  <a
                    className="btn btn-primary btn-lg w-100 fw-6"
                    onClick={handleSubmit(contactUs)}
                  >
                   {loading ?  <Spinner
                    thickness='4px'
                    speed='0.65s'
                    emptyColor='gray.200'
                    color='white'
                    size='lg'
                    /> :"Send"}
                  </a>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
 
  )
}

export default ContactUs