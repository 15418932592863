import { Box, Checkbox, FormControl, FormLabel, Input } from "@chakra-ui/react";
import React from "react";

const OpeningDays = ({
  days,
  changeCheckboxStatus,
  handleStartTime,
  handleEndTime,
  colorScheme,
  fontSize,
}) => {

  return (
    <div>
      {days &&
        days.map((day, index) => (
          <>
            <Box>
              <Checkbox
                isChecked={day?.isChecked}
                value="child"
                fontSize={fontSize}
                colorScheme={colorScheme}
                onChange={(e) => changeCheckboxStatus(e, index, day)}
              >
                {day.day}
              </Checkbox>
              {day.isChecked === true ? (
                // <InputStart day={day} />    \
                <FormControl
                  display="flex"
                  direction="row"
                  justifyContent="space-between"
                  mb="10px"
                  flexDirection={["column", "row"]}
                >
                  <Box w={["100%", "45%"]}>
                    <FormLabel fontSize="14px" fontWeight="400" mt={2} color="#6B6162">
                      Start Time
                    </FormLabel>
                    <Input
                      type="time"
                      defaultValue={day.startTime !== "" ? day.startTime : ""}
                      placeholder="10:10 AM"
                      color="#4B4447"
                      fontSize="14px"
                      fontWeight="400"
                      onChange={(e) => handleStartTime(e.target.value, day)}
                      shadow={"lg"}
                    />
                  </Box>
                  <Box w={["100%", "45%"]}>
                    <FormLabel fontSize="14px" fontWeight="400" mt={2} color="#6B6162">
                      End Time
                    </FormLabel>
                    <Input
                      type="time"
                      defaultValue={day.endTime !== "" ? day.endTime : ""}
                      placeholder="05:20 PM"
                      color="#4B4447"
                      onChange={(e) => handleEndTime(e.target.value, day)}
                      shadow={"lg"}
                    />
                  </Box>
                </FormControl>
              ) : (
                <div></div>
              )}
            </Box>
          </>
        ))}
    </div>
  );
};

export default OpeningDays;
