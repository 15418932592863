import {
  Box,
  Button,
  Checkbox,
  Flex,
  Grid,
  GridItem,
  HStack,
  Image,
  Input,
  Radio,
  RadioGroup,
  Text,
  Textarea,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { Select } from "@chakra-ui/react";

import { profilee, rowes } from "../../assets/images";
import { star, arrow } from "../../assets/icons";
import { FormControl, FormLabel } from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { useEffect } from "react";
import {
  deleteSingleOffer,
  getCategory,
  getOfferList,
  pauseSingleOffer,
  singleOfferDetails,
  statusChangeMultipleOffers,
  updateOffer,
} from "../../api/user";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import ChangeImages from "../../components/ChangeImages";
import { toast } from "react-toastify";
import Header from "../../components/Header";
import Layout from "../../components/Layout";
import moment from "moment";
import { DAYS, DAYSDATA } from "../../constants";
import EditChangeImages from "../../components/EditChangeImages";
import RangeSlider from "react-rangeslider";
import "react-rangeslider/lib/index.css";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup"


const Index = () => {
  const navigate = useNavigate();
  const [categories, setCategories] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const [categoryId, setCategoryId] = useState("");
  const [subCategoryId, setSubCategoryId] = useState("");
  const [value, setValue] = useState("");
  const [days, setDays] = useState([]);
  const [gallery, setGallery] = useState([]);
  const [peak, setPeak] = useState(false);
  const [offPeak, setOffPeak] = useState(false);
  const [anytime, setAnytime] = useState(false);
  const [user, setUser] = useState(null);
  const [checked, setChecked] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedSubCategory, setSelectedSubCategory] = useState("");
  const { seller } = useSelector((state) => state.seller);
  const [isStudentDisable, setIsStudentDisable] = useState(true);
  const [studentPrice, setStudentPrice] = useState("");
  const [studentFinalPrice, setStudentFinalPrice] = useState("");
  const [nhsFinalPrice, setNhsFinalPrice] = useState("");
  const [isNHSDisable, setIsNHSDisable] = useState(true);
  const [nhsPrice, setNhsPrice] = useState("");
  const [originalPrice, setOriginalPrice] = useState("");
  const [discountedPrice, setDiscountedPrice] = useState("");
  const [delivery, setDelivery] = useState([]);
  const [selectedDays, setSelectedDays] = useState(DAYSDATA);
  const [isParentChecked, setIsParentChecked] = useState(false);
  const [checkedDays, setCheckedDays] = useState([]);
  const [percentagePrice, setPercentagePrice] = useState("");
  const [isStudentChecked, setIsStudentChecked] = useState(false);
  const [isNhsChecked, setIsNhshecked] = useState(false);
  const [availableDays, setAvailableDays] = useState([]);
  const [range, setRange] = useState(0);

  const schema = yup
  .object({
    deliveryCharges: yup.string().required("Delivery charge is required when delivery option is selected."),
  
  })
  .required()

  const handleOnChange = (value) => {
    setRange(value);
  };

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getCategory()).then((res) => {
      setCategories(res.data.categories);
    });
  }, []);

  const { offer } = useSelector((state) => state.offers);

  const {
    register,
    handleSubmit,
    reset,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {deliveryCharges: ""}
  });

  const [minEndDate, setMinEndDate] = useState(moment(new Date()).format("YYYY-MM-DD"));
  const startDate = watch("startDate");
  React.useEffect(() => {
    if (startDate) {
      setMinEndDate(moment(startDate).format("YYYY-MM-DD"));
    }
  }, [startDate]);
  const params = useParams();
  const offerId = params.id;

  const handleOriginalPrice = (e) => {
    setOriginalPrice(e.target.value);
  };
  const handleDiscountedPrice = (e) => {
    setDiscountedPrice(e.target.value);
  };
  const handlePercentagePrice = (e) => {
    setPercentagePrice(e.target.value);
  };

  const handleDelivery = (e) => {
    if (e.target.checked) {
      setDelivery([...delivery, e.target.value]);
    } else {
      const filteredData = delivery.filter((item) => item !== e.target.value);
      setDelivery(filteredData);
    }
  };

  useEffect(() => {
    if (discountedPrice === "") {
      setPercentagePrice("");
    } else if (originalPrice === "" && discountedPrice !== "") {
      setPercentagePrice("");
    } else if (originalPrice === "" && percentagePrice !== "") {
      setDiscountedPrice("");
    } else if (originalPrice !== "" && discountedPrice === "") {
      setPercentagePrice("");
    } else if (parseFloat(discountedPrice) > parseFloat(originalPrice)) {
      toast.error("Discounted Price can't be greater than original price");
      setPercentagePrice("");
      setDiscountedPrice("");
    } else if (originalPrice !== "" && discountedPrice !== "") {
      let price = ((originalPrice - discountedPrice) / originalPrice)*100;
      setPercentagePrice(price.toFixed(2));
    }
  }, [discountedPrice,originalPrice]);

  useEffect(() => {
    if (offerId) {
      dispatch(singleOfferDetails(offerId)).then((res) => {
        setGallery(res?.data?.offerDetails?.gallery);
        setDays(res?.data?.offerDetails?.days);
        setOriginalPrice(res?.data?.offerDetails?.price.orignalPrice);
        setDiscountedPrice(res?.data?.offerDetails?.price.discountedPrice);
        setPercentagePrice(res?.data?.offerDetails?.price.percentagePrice);
        if (res?.data?.offerDetails.studentDiscount !== null) {
          setIsStudentChecked(true);
          setIsStudentDisable(false);
          setStudentPrice(res?.data?.offerDetails.studentPrice);
          setStudentFinalPrice(res?.data?.offerDetails.studentDiscount);
        }
        if (res?.data?.offerDetails.NHSDiscount !== null) {
          setIsNhshecked(true);
          setIsNHSDisable(false);
          setNhsPrice(res?.data?.offerDetails.NHSPrice);
          setNhsFinalPrice(res?.data?.offerDetails.NHSDiscount);
        }
        setDelivery(res?.data?.offerDetails?.delivery);
        setChecked(res?.data?.offerDetails?.popularity);
        setAvailableDays(res?.data?.offerDetails?.days);
        if (res?.data?.offerDetails?.days?.length == 7) {
          setIsParentChecked(true);
        }

        setUser({
          offerName: res?.data?.offerDetails?.offerName || "hello",
          quantity: res?.data?.offerDetails?.quantity,
          startDate: res?.data?.offerDetails?.duration?.startDate?.slice(0, 10),
          endDate: res?.data?.offerDetails?.duration?.endDate?.slice(0, 10),
          ageRestrictedProduct: res?.data?.offerDetails?.ageRestrictedProduct,
          commission: res?.data?.offerDetails?.commision,
          specialDiscount: res?.data?.offerDetails?.specialDiscount,
          description: res?.data?.offerDetails?.description,
          cancellationPolicy: res?.data?.offerDetails?.cancellationPolicy,
          validUntill: res?.data?.offerDetails?.validUntill,
          terms: res?.data?.offerDetails?.terms,
          deliveryCharges:res?.data?.offerDetails?.deliveryCharges
        });
        setRange(res?.data?.offerDetails?.deliveryDistanceRange)
      });
    }
  }, [offerId]);

  useEffect(() => {
    if (categoryId) {
      const data = categories.filter(
        (item) => item.mainCategory === categoryId
      );
      setSubCategories(data[0]?.subCategory);
    }
  }, [categoryId]);

  useEffect(() => {
    reset(user);
  }, [user]);

  useEffect(() => {
    if (selectedDays.length < 1) {
      setSelectedDays(days);
    }
  }, [days]);

  const handleStudent = (e, type) => {
    if (type === "student") {
      if (e) {
        setIsStudentChecked(true);
        setIsStudentDisable(false);
      } else {
        setIsStudentChecked(false);
        setIsStudentDisable(true);
        setStudentPrice("");
        setStudentFinalPrice("");
      }
    } else {
      if (e) {
        setIsNhshecked(true);
        setIsNHSDisable(false);
      } else {
        setIsNhshecked(false);
        setIsNHSDisable(true);
        setNhsPrice("");
        setNhsFinalPrice("");
      }
    }
  };

  const handleStudentPrice = (e) => {
    const value = e.target.value
    setStudentPrice(value);
    if (originalPrice !== "" && value !== "") {
      const finalPrice =  `${originalPrice - (value / 100) * originalPrice}`;
      setStudentFinalPrice(parseFloat(finalPrice).toFixed(2));
    } else {
      setStudentFinalPrice("");
    }
  };

  const handleNhsPrice = (e) => {
    const price = e.target.value
    setNhsPrice(price);
    if (originalPrice !== "" && price !== "") {
      const finalPrice = `${originalPrice - (price / 100) * originalPrice}`
      setNhsFinalPrice(parseFloat(finalPrice).toFixed(2));
    } else {
      setNhsFinalPrice("");
    }
  };

  const handleCategory = (e) => {
    const { value } = e.target;
    setCategoryId(value);
    const data = categories.filter(
      (item) => item.mainCategory == e.target.value
    );
    const m = { mainCategory: "name", _id: "id" };
    const newData = (o) =>
      Object.assign(...Object.keys(o).map((k) => ({ [m[k] || k]: o[k] })));
    const newObj = newData(data[0]);
    setSelectedCategory({
      id: newObj.id,
      name: newObj.name,
    });
    setSubCategories(data);
    setSubCategoryId("");
  };

  const toggle = (evt) => {
    setChecked((current) =>
      current === evt.target.value ? null : evt.target.value
    );
  };

  useEffect(() => {
    const myUsers = [...selectedDays];
    if (isParentChecked) {
      myUsers.map((item) => {
        item.isChecked = true;
        return item;
      });
    } else {
      myUsers.map((item) => {
        item.isChecked = availableDays.includes(item.day);
        return item;
      });
    }
    setSelectedDays([...myUsers]);
  }, [availableDays]);

  const changeCheckboxStatus = (e, id) => {
    const myUsers = [...selectedDays];
    const { checked } = e.target;
    myUsers.map((user) => {
      if (id === "allChecked") {
        setIsParentChecked(checked);
        user.isChecked = checked;
      } else {
        if (user.id === id) {
          user.isChecked = checked;
          if (checked) {
            setCheckedDays([...checkedDays, user]);
          } else {
            const removeUser = checkedDays.filter((item) => item !== user);
            setCheckedDays(removeUser);
          }
        }
        const isAllChildsChecked = myUsers.every(
          (user) => user.isChecked === true
        );
        if (isAllChildsChecked) {
          setIsParentChecked(checked);
        } else {
          setIsParentChecked(false);
        }
      }
      return user;
    });

    setSelectedDays([...myUsers]);
  };

  useEffect(() => {
    if (parseFloat(studentFinalPrice) > parseFloat(originalPrice)) {
      toast.error(
        "Student Effective price should not be greater than original price"
      );
      setStudentFinalPrice("");
      setStudentPrice("");
    }
  }, [studentFinalPrice, originalPrice]);

  // useEffect(() => {
  //   if (parseFloat(studentPrice) > 100) {
  //     toast.error("Student Percentage discount should not be greater than 100");

  //     setStudentPrice("");
  //     setStudentFinalPrice("");
  //   }
  // }, [studentPrice, originalPrice]);

  useEffect(() => {
    if (parseFloat(nhsFinalPrice) > parseFloat(originalPrice)) {
      toast.error(
        "NHS Effective price should not be greater than original price"
      );
      setNhsFinalPrice("");
      setNhsPrice("");
    }
  }, [nhsFinalPrice, originalPrice]);

  // useEffect(() => {
  //   if (parseFloat(nhsPrice) > 100) {
  //     toast.error("NHS Percentage discount should not be greater than 100");

  //     setNhsPrice("");
  //     setNhsFinalPrice("");
  //   }
  // }, [nhsPrice, originalPrice]);

  const [deliveryError, setDeliveryError] = useState("");
  const updateOffers = (data) => {
    if(nhsPrice > 100) {
      toast.error("NHS Percentage discount should not be greater than 100");
      return;
    }
    if (studentPrice > 100) {
      toast.error("Student Percentage discount should not be greater than 100")
      return
    }
    Number.prototype.toFixedNoRounding = function (n) {
      // const reg = new RegExp("^-?\\d+(?:\\.\\d{0," + n + "})?", "g", "");
      // const a = this.toString().match(reg)[0];
      // const dot = a.indexOf(".");
      // if (dot === -1) {
      //   // integer, insert decimal dot and pad up zeros
      //   return a + "." + "0".repeat(n);
      // }
      // const b = n - (a.length - dot) + 1;
      // return b > 0 ? a + "0".repeat(b) : a;
      const reg = new RegExp("^-?\\d+(?:\\.\\d{0," + n + "})?", "g");
      const match = this.toString().match(reg);
      if (!match) {
        throw new Error("Invalid number format");
      }
    
      const a = match[0];
      const dot = a.indexOf(".");
      
      if (dot === -1) {
        // integer, insert decimal dot and pad up zeros
        return a + "." + "0".repeat(n);
      }
    
      const b = n - (a.length - dot) + 1;
      return b > 0 ? a + "0".repeat(b) : a;
    };
    // if (delivery.includes("Delivery") && !data.deliveryCharges) {
    //   setDeliveryError("Delivery charge is required when delivery option is selected.");
    // }
    const originalPri = parseFloat(originalPrice).toFixedNoRounding(2);
    const discountedPri = parseFloat(discountedPrice).toFixedNoRounding(2);
    const sp = parseFloat(studentFinalPrice || 0).toFixedNoRounding(2);
    const np = parseFloat(nhsFinalPrice || 0).toFixedNoRounding(2);
    const apiData = {
      offerName: data.offerName,
      quantity: data.quantity,
      isReducedPrice: false,
      commision: 10,
      ageRestrictedProduct: data.ageRestrictedProduct,
      address: "50",
      description: data.description,
      days: selectedDays,
      gallery,
      cancellationPolicy: data.cancellationPolicy,
      validUntill: parseInt(data.validUntill),
      percentage: 10,
      specialDiscount: "50",
      studentDiscount: sp || 0,
      studentPrice: studentPrice || 0,
      NHSDiscount: np || 0,
      NHSPrice: nhsPrice || 0,
      deliveryDistanceRange:range,
      deliveryCharges:Number(data.deliveryCharges),
      price: {
        orignalPrice: originalPri,
        discountedPrice: discountedPri,
        percentagePrice: percentagePrice,
      },
      duration: {
        startDate: moment(data.startDate).format("DD/MM/YYYY"),
        endDate: moment(data.endDate).format("DD/MM/YYYY"),
      },
      delivery: delivery,
      terms: data.terms,
      popularity: checked,
    };
    dispatch(updateOffer(offerId, apiData))
      .then((res) => {
        toast.success("Offer Updated Successfully!");
        navigate("/manage-offers");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handlesubCategory = (e) => {
    const subCate = subCategories.filter(
      (item) => item.name === e.target.value
    );
    setSubCategoryId(subCate[0].name);
    setSelectedSubCategory({
      id: subCate[0]._id,
      name: subCate[0].name,
    });
  };

  const handleClick = () => {
    navigate(-1);
  };

  const deleteOffer = () => {
    dispatch(deleteSingleOffer(offerId))
      .then((res) => {
        if (res.statusCode === 200) {
          toast.success(res.message);
          dispatch(getOfferList());
          navigate("/manage-offers");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const pauseOffer = () => {
    const apiData = {
      ids: [offerId],
      status: "pending",
    };
    dispatch(statusChangeMultipleOffers(apiData))
      .then((res) => {
        if (res.statusCode === 200) {
          dispatch(getOfferList());
          navigate("/manage-offers");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleSpacebarPress = (event) => {
    const { value } = event.target;
    if (event.key === ' ' && value.trim() === '') {
      alert('Spaces are not allowed in this field');
      event.preventDefault(); // Prevents the space character from being added to the input
    }
  }

  return (
    <Layout>
      <Box
        w={{ lg: "100%", md: "90%" }}
        mx={{ xl: "none", lg: "none", md: "auto" }}
      >
        <Box
          w={{ xl: "99%", lg: "100%", md: "100%" }}
          display="flex"
          justifyContent="space-between"
        >
          <Link variant="ghost" onClick={handleClick} bg="green">
            <Text
              fontFamily="Commissioner"
              color="#2E1A47"
              fontSize={{ xl: "20px", md: "18px" }}
              fontWeight="600"
              lineHeight="28px"
              letter="-4%"
            >
              {"<"}Back
            </Text>
          </Link>
          <Header />
        </Box>
        <Box w={{ xl: "95%", md: "94%" }}>
          <Text
            fontFamily="Rubik"
            color="#2E1A47"
            fontSize={{ xl: "34px", md: "30px" }}
            fontWeight="600"
            lineHeight="40px"
            letter="-4%"
          >
            Edit Offer
          </Text>
        </Box>
        <Box>
          <Box
            w={{ xl: "60%", md: "98%" }}
            shadow="xl"
            bg="white"
            display="flex"
            mt="15px"
            justifyContent="space-between"
            p="8px"
            borderRadius="md"
          >
            <Box w="45%" mt="10px">
              <Text fontSize="20px" fontWeight="600" color="#1E0F42">
                {offer.offerDetails?.offerName}
              </Text>
              <Box mt="20px">
                <Flex>
                  <Image
                    src={seller?.profileImage || profilee}
                    h="72px"
                    w="72px"
                    rounded="full"
                  />
                  <Box mt="9px" ml="3">
                    <Text fontSize="16px" fontWeight="600" color="#1E0F42">
                      {seller?.name?.businessName}
                    </Text>
                    <Flex>
                      {/* <Image src={star} /> */}
                      <svg width="90" height="18" viewBox="0 0 90 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                     <path d="M9 1.5L11.3175 6.195L16.5 6.9525L12.75 10.605L13.635 15.765L9 13.3275L4.365 15.765L5.25 10.605L1.5 6.9525L6.6825 6.195L9 1.5Z" fill="#1E0F42" stroke="#1E0F42" stroke-width="2" strokeLinecap="round" stroke-linejoin="round"/>
                      <path d="M27 1.5L29.3175 6.195L34.5 6.9525L30.75 10.605L31.635 15.765L27 13.3275L22.365 15.765L23.25 10.605L19.5 6.9525L24.6825 6.195L27 1.5Z" fill="#1E0F42" stroke="#1E0F42" stroke-width="2" strokeLinecap="round" stroke-linejoin="round"/>
                        <path d="M45 1.5L47.3175 6.195L52.5 6.9525L48.75 10.605L49.635 15.765L45 13.3275L40.365 15.765L41.25 10.605L37.5 6.9525L42.6825 6.195L45 1.5Z" fill="#1E0F42" stroke="#1E0F42" stroke-width="2" strokeLinecap="round" stroke-linejoin="round"/>
                       <path d="M63 1.5L65.3175 6.195L70.5 6.9525L66.75 10.605L67.635 15.765L63 13.3275L58.365 15.765L59.25 10.605L55.5 6.9525L60.6825 6.195L63 1.5Z" fill="#1E0F42" stroke="#1E0F42" stroke-width="2" strokeLinecap="round" stroke-linejoin="round"/>
                       <path d="M81 1.5L83.3175 6.195L88.5 6.9525L84.75 10.605L85.635 15.765L81 13.3275L76.365 15.765L77.25 10.605L73.5 6.9525L78.6825 6.195L81 1.5Z" fill="#CCC9C7" stroke="#CCC9C7" stroke-width="2" strokeLinecap="round" stroke-linejoin="round"/>
                        </svg>
                      <Text>(88)</Text>
                    </Flex>
                  </Box>
                  {/* <Image src={arrow} ml="30px" /> */}
                  <svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M1 13L7 7L1 1" stroke="#3B3538" stroke-width="2" strokeLinecap="round" stroke-linejoin="round"/>
                   </svg>

                </Flex>
              </Box>
            </Box>
            <Box w="45%" mt="10px">
              <Box ml="40%">
                <Text
                  fontStyle="normal"
                  fontFamily="Commissioner"
                  fontWeight="400"
                  fontSize="14px"
                  lineHeight="16px"
                  color="#6B6162"
                >
                  Quick Actions
                </Text>
                <Button
                  bg="#1E0F42"
                  color="white"
                  fontSize="14px"
                  borderRadius="5px"
                  mt="10px"
                  w="100%"
                  onClick={pauseOffer}
                >
                  Pause Offer
                </Button>
                <br />
                <Button
                  bg="#FFA3AC"
                  color="black"
                  fontSize="14px"
                  borderRadius="5px"
                  mt="10px"
                  w="100%"
                  onClick={deleteOffer}
                >
                  Delete Offer
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>
        <EditChangeImages gallery={gallery} setGallery={setGallery} />
        <Box w={{ xl: "61%", lg: "70%" }} p={1} mt={4}>
          <Box w="100%">
            <Box>
              <Box w="100%">
                <FormControl>
                  <FormLabel
                    fontSize="14px"
                    fontWeight="400"
                    color="#6B6162"
                    mb="0.5"
                  >
                    Offer name
                  </FormLabel>
                  <Input
                    w="100%"
                    fontSize="14px"
                    fontWeight="400"
                    color="#4B4447"
                    placeholder="Discounted Sausage Rolls"
                    shadow="lg"
                    {...register("offerName")}
                    bg="white"
                  />
                  <Text my={1} color="red" fontSize="xs">
                    {errors.offerName?.message}
                  </Text>
                </FormControl>
              </Box>
              <Box
                display="flex"
                justifyContent={"space-between"}
                w="100%"
                mt={4}
              >
                {/* <Box w="48%">
                  <FormControl>
                    <FormLabel fontSize="14px" fontWeight="400" color="#6B6162">
                      Category
                    </FormLabel>
                    <Select
                      placeholder="Select Category"
                      fontSize="14px"
                      onChange={handleCategory}
                      shadow="lg"
                      bg="white"
                      value={categoryId}
                    >
                      {categories?.map((value) => (
                        <option key={value._id} value={value.mainCategory}>
                          {value.mainCategory}
                        </option>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
                <Box w="48%">
                  <FormControl>
                    <FormLabel fontSize="14px" fontWeight="400" color="#6B6162">
                      Sub-category
                    </FormLabel>
                    <Select
                      shadow="lg"
                      bg="white"
                      fontSize="14px"
                      placeholder="Select Sub-Category"
                      onChange={handlesubCategory}
                      value={subCategoryId}
                    >
                      {subCategories?.map((value) => (
                        <option key={value._id} value={value.name}>
                          {value.name}
                        </option>
                      ))}
                    </Select>
                  </FormControl>
                </Box> */}
              </Box>
            </Box>
            <Box
              w="100%"
              display={{ lg: "flex", md: "" }}
              justifyContent="space-between"
              gap={6}
            >
              <Box
                w={{ lg: "50%", md: "100%" }}
                display="flex"
                justifyContent="space-between"
              >
                <Box w="46%" mt={3}>
                  <FormControl>
                    <FormLabel fontSize="14px" fontWeight="400" color="#6B6162">
                      Quantity
                    </FormLabel>
                    <Input
                      fontSize="14px"
                      fontWeight="400"
                      color="#4B4447"
                      shadow="lg"
                      bg="white"
                      {...register("quantity")}
                      onKeyDown={(event) => {
                        if (event.key === ".") {
                          event.preventDefault();
                        }
                      }}
                      onInput={(event) => {
                        event.target.value = event.target.value.replace(
                          /[^0-9]*/g,
                          ""
                        );
                      }}
                    />
                  </FormControl>
                  <Text my={1} color="red" fontSize="xs">
                    {errors.quantity?.message}
                  </Text>
                </Box>
                <Box w="46%" mt={3}>
                  <FormControl>
                    <FormLabel fontSize="14px" fontWeight="400" color="#6B6162">
                      Original Price
                    </FormLabel>
                    <Input
                      fontSize="14px"
                      fontWeight="400"
                      color="#4B4447"
                      shadow="lg"
                      bg="white"
                      type="number"
                      value={originalPrice}
                      onChange={handleOriginalPrice}
                      // {...register('orignalPrice', {required: true})}
                    />
                    <Text my={1} color="red" fontSize="xs">
                      {errors.orignalPrice?.message}
                    </Text>
                  </FormControl>
                </Box>
              </Box>
              <Box
                w={{ lg: "50%", md: "100%" }}
                display="flex"
                justifyContent="space-between"
              >
                <Box w="46%" mt={3}>
                  <FormControl>
                    <FormLabel fontSize="14px" fontWeight="400" color="#6B6162">
                    New Discount Price

                    </FormLabel>
                    <Input
                      fontSize="14px"
                      fontWeight="400"
                      color="#4B4447"
                      shadow="lg"
                      bg="white"
                      type="number"
                      // isDisabled={isDiscountDisabled}
                      value={discountedPrice}
                      onChange={handleDiscountedPrice}
                    />
                    <Text my={1} color="red" fontSize="xs">
                      {errors.discountedPrice?.message}
                    </Text>
                  </FormControl>
                </Box>
                <Box w="47%" mt={3}>
                  <FormControl>
                    <FormLabel fontSize="14px" fontWeight="400" color="#6B6162">
                    Discount Percentage
                    </FormLabel>
                    <Input
                      fontSize="14px"
                      fontWeight="400"
                      color="#4B4447"
                      shadow="lg"
                      bg="white"
                      type="number"
                      min={1}
                      max={100}
                      cursor={"not-allowed"}
                      disabled
                      // isDisabled={isPercentageDisabled}
                      value={percentagePrice}
                      onChange={handlePercentagePrice}
                    />
                    <Text my={1} color="red" fontSize="xs">
                      {errors.percentagePrice?.message}
                    </Text>
                  </FormControl>
                </Box>
              </Box>
            </Box>

            <Box display="flex" justifyContent="space-between">
              <Box w={{ lg: "49%", md: "46%" }} mt={3}>
                <FormControl>
                  <FormLabel
                    fontSize="14px"
                    fontWeight="400"
                    color="#6B6162"
                    mb="0.5"
                  >
                    Start Date
                  </FormLabel>
                  <Input
                    fontSize="14px"
                    fontWeight="400"
                    color="#4B4447"
                    type="date"
                    min={moment(new Date()).format("YYYY-MM-DD")}
                    shadow="lg"
                    bg="white"
                    {...register("startDate")}
                  />
                  <Text my={1} color="red" fontSize="xs">
                    {errors.startDate?.message}
                  </Text>
                </FormControl>
              </Box>
              <Box w={{ lg: "48%", md: "47%" }} mt={3}>
                <FormControl>
                  <FormLabel
                    fontSize="14px"
                    fontWeight="400"
                    color="#6B6162"
                    mb="0.5"
                  >
                    End Date
                  </FormLabel>
                  <Input
                    fontSize="14px"
                    fontWeight="400"
                    color="#4B4447"
                    type="date"
                    shadow="lg"
                    bg="white"
                    min={minEndDate}
                    {...register("endDate")}
                  />
                  <Text my={1} color="red" fontSize="xs">
                    {errors.endDate?.message}
                  </Text>
                </FormControl>
              </Box>
            </Box>

            <Box display="flex" justifyContent="space-between">
              <Box w="31%" mt={3}>
                <FormControl>
                  <FormLabel
                    fontSize="14px"
                    fontWeight="400"
                    color="#6B6162"
                    mb="0.5"
                  >
                    Special Discounts(%)
                  </FormLabel>
                  <HStack>
                    <Checkbox
                      colorScheme="pink"
                      isChecked={isStudentChecked}
                      onChange={(e) =>
                        handleStudent(e.target.checked, "student")
                      }
                    >
                      <Text fontSize="14px" w="69px" h="21px">
                        Student
                      </Text>
                    </Checkbox>
                    <Input
                      type="number"
                      bg="white"
                      shadow="lg"
                      disabled={isStudentDisable}
                      value={studentPrice}
                      onChange={handleStudentPrice}
                      // {...register("studentDiscount")}
                    />
                  </HStack>
                  <HStack my={2}>
                    <Checkbox
                      colorScheme="pink"
                      isChecked={isNhsChecked}
                      onChange={(e) => handleStudent(e.target.checked, "nhs")}
                    >
                      <Text fontSize="14px" w="69px" h="21px">
                        NHS
                      </Text>
                    </Checkbox>
                    <Input
                      type="number"
                      isDisabled={isNHSDisable}
                      onChange={handleNhsPrice}
                      bg="white"
                      value={nhsPrice}
                      shadow="lg"
                      // {...register("NHSDiscount")}
                    />
                  </HStack>
                  <Text my={1} color="red" fontSize="xs">
                    {errors.specialDiscount?.message}
                  </Text>
                </FormControl>
              </Box>

              <Box w="31%" mt={3}>
                <FormControl>
                  <FormLabel
                    fontSize="14px"
                    fontWeight="400"
                    color="#6B6162"
                    mb="0.5"
                  >
                    Effective Price
                  </FormLabel>
                  <Input
                    type="text"
                    readOnly={true}
                    fontSize="14px"
                    color="#4B4447"
                    shadow="lg"
                    bg="white"
                    value={studentFinalPrice}
                    fontWeight="400"
                    // {...register("commision")}
                  ></Input>
                  <Text my={1} color="red" fontSize="xs">
                    {errors.commission?.message}
                  </Text>
                </FormControl>
                <FormControl>
                  <Input
                    type="text"
                    readOnly={true}
                    fontSize="14px"
                    color="#4B4447"
                    shadow="lg"
                    bg="white"
                    value={nhsFinalPrice}
                    fontWeight="400"
                    // {...register("commision")}
                  ></Input>
                  <Text my={1} color="red" fontSize="xs">
                    {errors.commission?.message}
                  </Text>
                </FormControl>
              </Box>
              <Box w="31%" mt={3}>
                <FormControl>
                  <FormLabel
                    fontSize={{ lg: "14px", md: "13.5px" }}
                    fontWeight="400"
                    color="#6B6162"
                    mb="0.5"
                  >
                    Age restricted product?
                  </FormLabel>
                  <Select
                    placeholder="Select One"
                    fontSize="14px"
                    color="#4B4447"
                    shadow="lg"
                    bg="white"
                    fontWeight="400"
                    {...register("ageRestrictedProduct")}
                  >
                    <option value={true}>Yes</option>
                    <option value={false}>No</option>
                  </Select>
                </FormControl>
              </Box>
            </Box>
            <Box w={"100%"}>
              <FormControl>
                <FormLabel
                  fontSize="14px"
                  fontWeight="400"
                  color="#6B6162"
                  my="4"
                >
                  Distance Range
                </FormLabel>
                <RangeSlider
                  min={0}
                  max={30}
                  step={5}
                  labels={{
                    0: "0km",
                    5: "5Km",
                    10: "10Km",
                    15: "15km",
                    20: "20Km",
                    25: "25Km",
                    30: "30km",
                  }}
                  value={range}
                  onChange={handleOnChange}
                />
            
                <Text my={1} color="red" fontSize="xs">
                  {errors.offerName?.message}
                </Text>
              </FormControl>
            </Box>

            <Box
              display={{ lg: "flex", md: "" }}
              justifyContent="space-between"
            >
              <Box w={{ lg: "48%", md: "100%" }} mt={3}>
                <FormControl>
                  <FormLabel
                    fontSize="14px"
                    fontWeight="400"
                    color="#6B6162"
                    mb="0.5"
                  >
                    Cancellation Policy
                  </FormLabel>
                  <Textarea
                    fontSize="14px"
                    fontWeight="400"
                    color="#4B4447"
                    type="text"
                    shadow="lg"
                    bg="white"
                    {...register("cancellationPolicy")}
                  />
                  <Text my={1} color="red" fontSize="xs">
                    {errors.cancellationPolicy?.message}
                  </Text>
                </FormControl>
              </Box>
              <Box w={{ lg: "48%", md: "100%" }} mt={3}>
                <FormControl>
                  <FormLabel
                    fontSize="14px"
                    fontWeight="400"
                    color="#6B6162"
                    mb="0.5"
                  >
                    Cancellation validity from the date of purchase((In Days
                    Only))
                  </FormLabel>
                  <Input
                    fontSize="14px"
                    fontWeight="400"
                    type="number"
                    color="#4B4447"
                    shadow="lg"
                    bg="white"
                    h={20}
                    {...register("validUntill")}
                    onKeyDown={(event) => {
                      if (event.key === ".") {
                        event.preventDefault();
                      }
                    }}
                    onInput={(event) => {
                      event.target.value = event.target.value.replace(
                        /[^0-9]*/g,
                        ""
                      );
                    }}
                  />

                  <Text my={1} color="red" fontSize="xs">
                    {errors.validUntill?.message}
                  </Text>
                </FormControl>
              </Box>
            </Box>
            <Box>
              <FormControl>
                <FormLabel
                  fontSize="14px"
                  fontWeight="400"
                  color="#6B6162"
                  mb="0.5"
                >
                  Terms & Conditions
                </FormLabel>
                <Textarea
                  fontSize="14px"
                  fontWeight="400"
                  color="#4B4447"
                  type="text"
                  shadow="lg"
                  bg="white"
                  {...register("terms")}
                />
                <Text my={1} color="red" fontSize="xs">
                  {errors.terms?.message}
                </Text>
              </FormControl>
            </Box>
          </Box>

          <Box mt={5}>
            <Box>
              <Text fontSize="14px" color="#6B6162">
                Days
              </Text>
            </Box>
            <Box
              w="100%"
              shadow="lg"
              bg="white"
              rounded="lg"
              p={{ lg: 2, md: 3 }}
            >
              <Box w="67%" m={4}>
                <Checkbox
                  onChange={(e) => changeCheckboxStatus(e, "allChecked")}
                  isChecked={isParentChecked}
                  colorScheme="pink"
                >
                  Select All Days
                </Checkbox>
                <Grid
                  templateRows="repeat(3, 1fr)"
                  templateColumns="repeat(3, 1fr)"
                  gap={{ lg: 10, md: 3 }}
                >
                  {DAYSDATA.map((day, index) => (
                    <GridItem key={day.day} colSpan={1} w={160} display="flex">
                      <Box>
                        <Checkbox
                          isChecked={day?.isChecked}
                          colorScheme="pink"
                          value="child"
                          onChange={(e) => changeCheckboxStatus(e, index, day)}
                        >
                          <Text
                            fontSize="14px"
                            color="#3B3538"
                            w="74px"
                            h="21px"
                          >
                            {day.day}
                          </Text>
                        </Checkbox>
                      </Box>
                    </GridItem>
                  ))}
                </Grid>
                {/* <AllDays
            DAYS={users}
            changeCheckboxStatus={changeCheckboxStatus}
           
          /> */}
              </Box>
            </Box>
          </Box>

          <Box mt={6} w="100%" shadow="lg" bg="white" p={2} rounded="lg">
            <Box mt={3} w="73%">
              <RadioGroup colorScheme="pink" p={1}>
                <Box
                  m={5}
                  display="flex"
                  justifyContent={"space-between"}
                  w="full"
                >
                  <Box w="30%">
                    <Checkbox
                      value="Dine-in"
                      isChecked={delivery.includes("Dine-in")}
                      onChange={handleDelivery}
                      colorScheme="pink"
                    >
                      <Text fontSize="14px" h="21px" color="#3B3538">
                        Dine-in
                      </Text>
                    </Checkbox>
                  </Box>
                  <Box w="30%">
                    <Checkbox
                      value="Take-away"
                      isChecked={delivery.includes("Take-away")}
                      onChange={handleDelivery}
                      colorScheme="pink"
                    >
                      <Text fontSize="14px" h="21px" color="#3B3538">
                        Take-away
                      </Text>
                    </Checkbox>
                  </Box>
                  <Box w="30%">
                    <Checkbox
                      value="Delivery"
                      isChecked={delivery.includes("Delivery")}
                      onChange={handleDelivery}
                      colorScheme="pink"
                    >
                      <Text fontSize="14px" h="21px" color="#3B3538">
                        Delivery
                      </Text>
                    </Checkbox>
                  </Box>
                </Box>
                <Box
                  m={5}
                  display="flex"
                  justifyContent={"space-between"}
                  w="full"
                >
                  <Box w="30%">
                    <Checkbox
                      value="In-stores"
                      isChecked={delivery.includes("In-stores")}
                      onChange={handleDelivery}
                      colorScheme="pink"
                    >
                      <Text fontSize="14px" color="#3B3538">
                        In-stores
                      </Text>
                    </Checkbox>
                  </Box>
                  <Box w="30%">
                    <Checkbox
                      value="Online"
                      isChecked={delivery.includes("Online")}
                      onChange={handleDelivery}
                      colorScheme="pink"
                    >
                      <Text fontSize="14px" h="21px" color="#3B3538">
                        Online
                      </Text>
                    </Checkbox>
                  </Box>
                  <Box w="30%">
                    <Checkbox
                      value="Home Services"
                      isChecked={delivery.includes("Home Services")}
                      onChange={handleDelivery}
                      colorScheme="pink"
                    >
                      <Text fontSize="14px" h="21px" color="#3B3538">
                        Home Services
                      </Text>
                    </Checkbox>
                  </Box>
                </Box>
                <Box
                  m={5}
                  display="flex"
                  justifyContent={"space-between"}
                  w="full"
                >
                  <Box w="30%">
                    <Checkbox
                      value="Other"
                      isChecked={delivery.includes("Other")}
                      onChange={handleDelivery}
                      colorScheme="pink"
                    >
                      <Text fontSize="14px" color="#3B3538">
                        Other
                      </Text>
                    </Checkbox>
                  </Box>
                </Box>
              </RadioGroup>
            </Box>
          </Box>
          {/* <Text my={1} color="red" fontSize="xs">
                 {deliveryError}
                </Text> */}
          {(delivery.includes("Delivery") || delivery.includes("Online") || delivery.includes("Home Services")) && (
               <FormControl>
               <FormLabel
                  fontSize="14px"
                  fontWeight="400"
                  color="#6B6162"
                  mt="10"
                >
                  Delivery Charges
                </FormLabel>
               
                <Input
                  w="100%"
                  fontSize="14px"
                  fontWeight="400"
                  color="#4B4447"
                  placeholder="Delivery Charges"
                  shadow="lg"
                  defaultValue={""} 
                  type="number"
                  {...register("deliveryCharges", {
                    required: "Delivery charges are required",
                    pattern: {
                      value: /^\d*(\.\d{0,2})?$/, // Allow numbers with up to two decimal places
                      message: "Please enter a valid amount with up to two decimal places"
                    },
                    validate: (value) => value > 0 || "Amount must be greater than zero", // Ensure positive values only
                  })}
                  bg="white"
                  onKeyDown={(e) => {
                    handleSpacebarPress(e)
                    if (!/[0.1-9.9]/.test(e.key) && e.key !== "Backspace" && e.key !== "ArrowLeft" && e.key !== "ArrowRight" && e.key !== "Tab") {
                      e.preventDefault();
                    }
                  }}
                  onInput={(e) => {
                    // Trim input to limit two digits after decimal
                    if (e.target.value.includes(".")) {
                      const [integer, decimal] = e.target.value.split(".");
                      if (decimal && decimal.length > 2) {
                        e.target.value = `${integer}.${decimal.substring(0, 2)}`;
                      }
                    }
                    if (e.target.value === "0" || e.target.value === 0) {
                      e.target.value = "";
                    }
                  }}
                  
                  onBlur={(e) => {
                    // Clear the field if it contains "0" after the user leaves the input
                    if (e.target.value === "0" || e.target.value === "") {
                      e.target.value = "";
                    }
                  }}
                  onWheel={(e) => e.target.blur()}
                />
                  <p style={{fontSize: "13px", color: "red", marginTop: "2px"}}>{errors.deliveryCharges?.message}</p>
                {/* <Text my={1} color="red" fontSize="xs">
                 {deliveryError}
                </Text> */}
                </FormControl>
                  )}
          <Box
            mt={3}
            w="100%"
            shadow="lg"
            bg="white"
            p={2}
            rounded="lg"
            {...register("popularity")}
          >
            <Box mt={3} w="71%">
              <Box
                m={5}
                colorScheme="pink"
                display="flex"
                justifyContent="space-between"
              >
                <Box>
                  <Checkbox
                    colorScheme="pink"
                    value="peak"
                    isChecked={checked == "peak"}
                    onChange={toggle}
                  >
                    <Text fontSize="14px" w="69px" h="21px">
                      Peak
                    </Text>
                  </Checkbox>
                </Box>
                <Box>
                  <Checkbox
                    colorScheme="pink"
                    value="offPeak"
                    isChecked={checked == "offPeak"}
                    onChange={toggle}
                  >
                    <Text fontSize="14px" w="69px" h="21px">
                      Off-Peak
                    </Text>
                  </Checkbox>
                </Box>
                <Box>
                  <Checkbox
                    colorScheme="pink"
                    value="anyTime"
                    isChecked={checked == "anyTime"}
                    onChange={toggle}
                  >
                    <Text fontSize="14px" w="69px" h="21px">
                      Any Time
                    </Text>
                  </Checkbox>
                </Box>
              </Box>
            </Box>
          </Box>
          <Box>
            <Box mt={5}>
              <Text fontSize="14px" w="113px" h="21px" color="#6B6162">
                Offer description{" "}
              </Text>
              <Textarea
                shadow="lg"
                bg="white"
                placeholder="Lorem ipsum dolor sit amet, consectetur adipiscing elit."
                fontSize="14px"
                color="#4B4447"
                {...register("description")}
              />
              <Text my={1} color="red" fontSize="xs">
                {errors.description?.message}
              </Text>
            </Box>
            <Box w="full" align="center" mt={8}>
              <Box mt={2}>
                <Button
                  w="100%"
                  bg="#FF1A57"
                  color="white"
                  fontSize="14px"
                  fontFamily="Commissioner"
                  onClick={handleSubmit(updateOffers)}
                >
                  Save
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Layout>
  )
};

export default Index;
