import {
  Box,
  Button,
  Center,
  Checkbox,
  FormLabel,
  Grid,
  GridItem,
  HStack,
  Image,
  Input,
  InputGroup,
  InputRightElement,
  Select,
  Text,
  Textarea,
  VStack,
} from "@chakra-ui/react";
import React, { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import {
  getCategory,
  getSubCategory,
  singleFileUpload,
  uploadMultipleImages,
  userSignup,
} from "../../api/user";
import { frame, icon_15, updatedLogo } from "../../assets/icons";
import { getFromLocalStorage } from "../../helpers";
import { useDispatch, useSelector } from "react-redux";
import { GrFormClose } from "react-icons/gr";
import { ViewIcon, ViewOffIcon } from "@chakra-ui/icons";
import { yupResolver } from "@hookform/resolvers/yup";
import { toast } from "react-toastify";
import { profilee } from "../../assets/images";
import { DAYS } from "../../constants";
import GooglePlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-google-places-autocomplete";
import DaysTime from "../../components/DaysTime";
import { signupUpValidator } from "../../helpers/validation";
import { onMessageListener, requestForToken } from "../../firebase";
import { FaMapMarkerAlt } from "react-icons/fa";

import { components } from 'react-select';




const Signup = () => {
  
  const [show, setShow] = React.useState(false);
  const [showPassword, setShowPassword] = React.useState(false);
  const imageRef = useRef();
  const handleClick = () => setShow(!show);
  const handleShowPassword = () => setShowPassword(!showPassword);
  const { categories } = useSelector((state) => state.categories);
  const { subCategories } = useSelector((state) => state.categories);
  const [isLoading, setIsLoading] = useState(false);
  const [images, setImages] = useState([]);
  const [imageURLS, setImageURLs] = useState([]);
  const [uploadedImages, setUploadedImages] = useState([]);
  const [preview, setPreview] = useState(null);
  const [coordinates, setCoordinates] = useState(null);
  // const [openingDays, setOpeningDays] = useState([]);
  const [profile, setProfile] = useState("");
  const [users, setUsers] = useState(DAYS);
  const [isParentChecked, setIsParentChecked] = useState(false);
  const [checkedDays, setCheckedDays] = useState([]);
  const [isSubscribed, setIsSubscribed] = useState(false);
  const [isCommission, SetIsCommission] = useState(false);
  const [value, setValue] = useState(null);
  const [inputValue, setInputValue] = useState('');

  const [location, setLocation] = useState({
    lat: "",
    lng: "",
  });
  const [isTokenFound, setTokenFound] = useState(false);
  const [currentToken, setCurrentToken] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const [notification, setNotification] = useState({ title: "", body: "" });
  requestForToken(setTokenFound, setCurrentToken);

  onMessageListener()
    .then((payload) => {
      setShow(true);
      setNotification({
        title: payload.notification.title,
        body: payload.notification.body,
      });
      toast.success(payload.data.title);
      // self.registration.showNotification(payload.data.title);
    })
    .catch((err) => console.log("failed: ", err));
    


    const [locationValue, setLocationValue] = useState(null);
    const CustomDropdownIndicator = () => {
      return <FaMapMarkerAlt
      onClick={fetchCurrentLocation}
      style={{ color: 'grey', marginRight: '10px',cursor: 'pointer' }} />;
    };
    
    const fetchCurrentLocation = () => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const { latitude, longitude } = position.coords;
            // Use reverse geocoding to get the address from the coordinates
            const geocoder = new window.google.maps.Geocoder();
            const latlng = { lat: latitude, lng: longitude };
    
            geocoder.geocode({ location: latlng }, (results, status) => {
              if (status === 'OK') {
                if (results[0]) {
                  setValue({
                    label: results[0].formatted_address,
                    value: results[0].place_id,
                  });
                  // setInputValue(results[0].formatted_address); 
                } else {
                  console.log('No results found');
                }
              } else {
                console.log('Geocoder failed due to: ' + status);
              }
            });
          },
          (error) => {
            console.log('Error fetching the current location: ', error);
          }
        );
      } else {
        console.log('Geolocation is not supported by this browser.');
      }
    };
    
  useEffect(() => {
    if (value) {
      geocodeByAddress(value.label)
        .then((results) => getLatLng(results[0]))
        .then(({ lat, lng }) =>
          setLocation({
            lat,
            lng,
          })
        );
    }
  }, [value]);

  useEffect(() => {
    getCategories();
  }, []);

  const getCategories = () => {
    dispatch(getCategory());
  };
 const [subCatId,setSubCatId] = useState("")

  const handleSubCategoryId = (id) => {
    setSubCatId(id)
  }
 
  useEffect(() => {
   dispatch(getSubCategory(subCatId))
   .then((res) => {
    // console.log(res?.data)
   }).catch((err) => {
    console.log(err)
   })
  },[subCatId])

  const handleChange = (e) => {
    const img = e.target.files[0];
    // console.log(img, "img");
    if (img) {
      dispatch(singleFileUpload(img))
        .then((res) => {
          // console.log(res, "res");
          if (res.statusCode === 200) {
            setProfile(res.data.image);
          }
        })
        .catch((error) => console.log(error));
    }
    setPreview(URL.createObjectURL(img));
  };

  const handleButtonClick = (e) => {
    imageRef.current.click();
  };

  useEffect(() => {
    if (images.length < 1) return;
    const newImageUrls = [];
    images.forEach((image) => {
      newImageUrls.push(URL.createObjectURL(image));
    });
    setImageURLs(newImageUrls);
  }, [images]);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const token = getFromLocalStorage("token");

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(signupUpValidator),
  });

  useEffect(() => {
    if (token) {
      navigate("/home");
    }
  }, [token]);

  const handleSignup = (data) => {
    if(!location.lat || !location.lng){
      toast.error("please enter your address")
    }
    // else{
      // if(
      //   (isParentChecked === false && !checkedDays?.length) ||
      //   (!imageURLS?.length && !images?.length && !uploadedImages?.length) ||
      //   !uploadedImages.length || 
      //   !openingDays.length 
      // ) {
      //   toast.error("Please select image or days")
      //   return
       // Check for images
    // if (!imageURLS?.length && !images?.length && !uploadedImages?.length) {
    //   toast.error("Please select at least one image");
    //   return;
    // }

    // Check for opening days
    // if (!openingDays.length) {
    //   toast.error("Please select at least one opening day");
    //   return;
    // }

    // Check for parent check and days
    // if (isParentChecked === false && !checkedDays?.length) {
    //   toast.error("Please select the required days");
    //   return;
    // } 
    // } 
    else  {
        const { category,subCategory } = data;
        const newCate = JSON.parse(category);
        const newSUbCate = JSON.parse(subCategory);
        const cate = {
          id: newCate._id,
          name: newCate.mainCategory,
        };
        const subCat = {
          id:newSUbCate._id,
          name:newSUbCate.name
        }
        const allData = {
          ...data,
          gallery: "",
          // gallery: uploadedImages,
          profileImage: profile,
          latitude: location.lat || coordinates.latitude,
          longitude: location.lng || coordinates.longitude,
          // openingDays: openingDays,
          openingDays: [],
          address: value?.label || "testing dummi",
          category: cate,
          subCategory : subCat,
        };
        const apiData = { ...allData, deviceToken: currentToken };
    // return    console.log(apiData, "apiData signup");
        setIsLoading(true);
        dispatch(userSignup(apiData))
          .then((res) => {
            if (res.statusCode === 200) {
              setIsLoading(false);
              toast.success(res.message);
              navigate("/login");
            }
          })
          .catch((err) => {
            setIsLoading(false);
            toast.error(err.message);
          });
      
    }
  };

  // const handleImages = (e) => {
  //   setImages([...e.target.files]);
  //   dispatch(uploadMultipleImages(e.target.files))
  //     .then((res) => {
  //       const newArray = res.map(function (item) {
  //         delete item.contentType;
  //         return item["imageUrl"];
  //       });
  //       setUploadedImages(newArray);
  //     })
  //     .catch((err) => console.log(err));
  // };

  const deleteImage = (img) => {
    // console.log(img)
    const newImgURL = imageURLS.filter((image) => image !== img);
    // console.log(newImgURL,"newImgURL")
    setImageURLs(newImgURL);
  };

  // console.log(imageURLS,"imageUrls")

  // const handleStartTime = (e, user) => {
  //   const newUsers = openingDays.length > 0 ? [...openingDays] : [...users];
  //   const filteredDay = newUsers.map((item) =>
  //     item.day === user.day ? { ...item, startTime: e } : item
  //   );
  //   setOpeningDays(filteredDay);
  // };

  // const handleEndTime = (e, user) => {
  //   const newUsers = openingDays.length > 0 ? [...openingDays] : [...users];
  //   const filteredDay = newUsers.map((item) =>
  //     item.day === user.day ? { ...item, endTime: e } : item
  //   );
  //   setOpeningDays(filteredDay);
  // };

  useEffect(() => {
    navigator.geolocation.getCurrentPosition((position) => {
      const cordinates = {
        latitude: position.coords.latitude,
        longitude: position.coords.longitude,
      };
      setCoordinates(cordinates);
    });
  }, []);

  // const changeCheckboxStatus = (e, id) => {
  //   const myUsers = [...users];
  //   const { checked } = e.target;
  //   myUsers?.map((user) => {
  //     if (id === "p1") {
  //       setIsParentChecked(checked);
  //       user.isChecked = checked;
  //     } else {
  //       if (user.id === id) {
  //         user.isChecked = checked;
  //         if (checked) {
  //           setCheckedDays([...checkedDays, user]);
  //         } else {
  //           const removeUser = checkedDays.filter((item) => item !== user);
  //           setCheckedDays(removeUser);
  //         }
  //       }
  //       const isAllChildsChecked = myUsers.every(
  //         (user) => user.isChecked === true
  //       );
  //       if (isAllChildsChecked) {
  //         setIsParentChecked(checked);
  //       } else {
  //         setIsParentChecked(false);
  //       }
  //     }
  //     return user;
  //   });

  //   setUsers([...myUsers]);
  // };

  useEffect(() => {
    if (!isSubscribed) {
      reset({
        amount: "",
        subscriptionPlan: "",
      });
    }
  }, [isSubscribed]);

  const handleSubscription = (e) => {
    if (e.target.checked) {
      setIsSubscribed(true);
    } else {
      setIsSubscribed(false);
      reset({
        amount: "",
        subscriptionPlan: "",
      });
    }
  };

  const handleCommission = (e) => {
    if (e.target.checked) {
      SetIsCommission(true);
    } else {
      SetIsCommission(false);
      reset({
        commission: "",
        subscriptionPlan: "",
      });
    }
  };

  const handleSpacebarPress = (event) => {
    const { value } = event.target;
    if (event.key === ' ' && value.trim() === '') {
      alert('Spaces are not allowed in this field');
      event.preventDefault(); // Prevents the space character from being added to the input
    }
  }
  // function handleSpacebar(event) {
  //   if (event.key === " " && email.value.trim() === "") {
  //     event.preventDefault();
  //     setError("email", {
  //       type: "manual",
  //       message: "Email cannot start with a space",
  //     });
  //   }
  // }
  const handleAddress = () => {
    if (!value) {
      alert("Please select an address.");
    } else {
      // Proceed with form submission or next steps
      console.log("Address selected:", value);
    }
  };
  return (
    <Box
      display={"grid"}
      placeItems={"center"}
      flexDirection={["column", "row"]}
      w="full"
      mt={8}
    >
      <Box w={["95%", "",]} display={"grid"} placeItems={"center"} gap="40px">
        <VStack>
          <Image src={updatedLogo} />
          <Text fontSize="xs">Seller Dashboard V1.0</Text>
          <Text fontSize="23px" fontWeight="600" color="#3B3538">
            Sign Up
          </Text>
          <Text fontSize="14px" fontWeight="500" color="#3B3538">
            Fill the details and create your account.
          </Text>
        </VStack>
        <Center mt={4}>
          <Box position="relative" w={"70px"}>
            <Image
              src={preview || profilee}
              width="70px"
              height="70px"
              objectFit={"cover"}
              borderRadius="full"
              {...register("profileImage")}
            />
            <Image
              src={icon_15}
              bg="#1E0F42"
              p="5px"
              top={-0.5}
              right={-1}
              borderRadius="100%"
              position="absolute"
              h="25px"
              w="25px"
              onClick={handleButtonClick}
            />
          </Box>
        </Center>
        <Input
          type="file"
          display="none"
          accept="image/png, image/jpeg, image/jpg"
          ref={imageRef}
          onChange={handleChange}
        />
        <Box mt="10px" mb="30px" maxWidth={"350px"}>
          <FormLabel
            color="#6B6162"
            fontSize="14px"
            fontWeight="400"
            alignSelf="flex-start"
            mb="0.5"
          >
            Business Name
          </FormLabel>
          <Input
            bg="white"
            shadow="lg"
            type="text"
            color="#6B6162"
            fontSize="14px"
            fontWeight="400"
            {...register("businessName")}
            onKeyDown={handleSpacebarPress} 
          />
          <Text my={1} color="red" fontSize="xs">
            {errors.businessName?.message}
          </Text>
          <FormLabel
            color="#6B6162"
            fontSize="14px"
            fontWeight="400"
            alignSelf="flex-start"
            mt={["10px", "20px"]}
            mb="0.5"
          >
            Contact Person
          </FormLabel>
          <Input
            bg="white"
            shadow="lg"
            type="text"
            color="#6B6162"
            fontSize="14px"
            fontWeight="400"
            {...register("contactPerson")}
            onKeyDown={handleSpacebarPress}
          />
          <Text my={1} color="red" fontSize="xs">
            {errors.contactPerson?.message}
          </Text>
          <FormLabel
            color="#6B6162"
            fontSize="14px"
            fontWeight="400"
            alignSelf="flex-start"
            mt={["10px", "20px"]}
            mb="0.5"
          >
            Contact Email
          </FormLabel>
          <Input
            bg="white"
            shadow="lg"
            type="email"
            color="#6B6162"
            fontSize="14px"
            fontWeight="400"
            {...register("email")}
            onKeyDown={handleSpacebarPress}
          />
          <Text my={1} color="red" fontSize="xs">
            {errors.email?.message}
          </Text>
          <FormLabel
            color="#6B6162"
            fontSize="14px"
            fontWeight="400"
            alignSelf="flex-start"

            mt={["10px", "20px"]}
            mb="0.5"
          >
            Password
          </FormLabel>
          <InputGroup>
            <Input
              bg="white"
              shadow="lg"
              minLength={"8"}
              maxLength={"16"}
              pr="4.5rem"
              type={showPassword ? "text" : "password"}
              outline="none"
              {...register("password")}
              onKeyDown={handleSpacebarPress}
            />
            <InputRightElement width="4.5rem">
              {showPassword ? <ViewIcon 
                  h="1.75rem"
                size="lg"
                color="#6B6162"
                onClick={handleShowPassword}
              /> : <ViewOffIcon
              h="1.75rem"
              size="lg"
              color="#6B6162"
              onClick={handleShowPassword}
            />}
            </InputRightElement>
          </InputGroup>
          <Text my={1} color="red" fontSize="xs" width="350px" >
            {errors.password?.message}
          </Text>
          <FormLabel
            color="#6B6162"
            fontSize="14px"
            fontWeight="400"
            alignSelf="flex-start"
            mt={["10px", "20px"]}
            mb="0.5"
          >
            Confirm Password
          </FormLabel>

          <InputGroup>
            <Input
              bg="white"
              shadow="lg"
              pr="4.5rem"
              minLength={"8"}
              maxLength={"16"}
              type={show ? "text" : "password"}
              {...register("confirmPassword")}
              onKeyDown={handleSpacebarPress}
            />
            <InputRightElement width="4.5rem">
            {show ? <ViewIcon 
              h="1.75rem"
                size="lg"
                color="#6B6162"
                onClick={handleClick}
              /> : <ViewOffIcon
              h="1.75rem"
              size="lg"
              color="#6B6162"
              onClick={handleClick}
            />}
            </InputRightElement>
          </InputGroup>
          <Text my={1} color="red" fontSize="xs">
            {errors.confirmPassword?.message}
          </Text>
          {/* <HStack alignItems="center" f="full" my={4}>
            <Checkbox onChange={handleSubscription}>
              <Text fontSize="14px" fontWeight="400" color="#6B6162">
                Subscription Plan
              </Text>
            </Checkbox>
          </HStack>

          <Select
            placeholder="Select Package"
            fontSize="14px"
            color="#6B6162"
            bg="white"
            my={2}
            defaultValue=""
            disabled={isSubscribed ? false : true}
            {...register("subscriptionPlan")}
          >
            <option value="Yearly">Yearly</option>
            <option value="Monthly">Monthly</option>
          </Select>
          <Text my={1} color="red" fontSize="xs">
            {errorMessage}
          </Text>

          <FormLabel
            color="#6B6162"
            fontSize="14px"
            fontWeight="400"
            alignSelf="flex-start"
            my={2}
          >
            Amount
          </FormLabel>
          <Input
            bg="white"
            shadow="lg"
            type="number"
            color="#6B6162"
            fontSize="14px"
            fontWeight="400"
            disabled={isSubscribed ? false : true}
            {...register("amount")}
          />
          <Text my={1} color="red" fontSize="xs">
            {errors.amount?.message}
          </Text>

          <HStack alignItems="center" f="full" my={4}>
            <Checkbox onChange={handleCommission}>
              <Text fontSize="14px" fontWeight="400" color="#6B6162">
                Commission
              </Text>
            </Checkbox>
          </HStack>
          <Input
            bg="white"
            shadow="lg"
            type="number"
            color="#6B6162"
            fontSize="14px"
            fontWeight="400"
            disabled={isCommission ? false : true}
            {...register("commission")}
          />
          <Text my={1} color="red" fontSize="xs"></Text>

          <FormLabel
            color="#6B6162"
            fontSize="14px"
            fontWeight="400"
            alignSelf="flex-start"
            my={2}
          >
            Transaction Fee
          </FormLabel>
          <Input
            bg="white"
            shadow="lg"
            type="number"
            color="#6B6162"
            fontSize="14px"
            fontWeight="400"
            disabled={isCommission ? false : true}
            {...register("transactionFee")}
          />
          <Text my={1} color="red" fontSize="xs">
            {errors.transactionFee?.message}
          </Text> */}

          <FormLabel
            color="#6B6162"
            fontSize="14px"
            fontWeight="400"
            alignSelf="flex-start"
            mt={["10px", "20px"]}
            mb="0.5"
          >
            Address
          </FormLabel>
          <div className="add_frm">
            <GooglePlacesAutocomplete
              className="form-control border_primary c_sel has-search "
              apiKey={process.env.REACT_APP_GOOGLE_API_KEY}
              selectProps={{
                value,
                onChange: setValue,
                // inputValue: inputValue,
                // onInputChange: (newInputValue) => setInputValue(newInputValue),
                components: {
                  DropdownIndicator: (props) => <CustomDropdownIndicator fetchCurrentLocation={fetchCurrentLocation} {...props} />,
                  IndicatorSeparator: () => null, // Optional: Remove the default indicator separator
                  // Menu: (props) => (
                  //   inputValue && props.children && props.children.length > 0 ? (
                  //     <components.Menu {...props} />
                  //   ) : null
                  // ),
                }
              }}
            />
            </div>      
          {/* <FormLabel
            color="#6B6162"
            fontSize="14px"
            fontWeight="400"
            alignSelf="flex-start"
            mt={["10px", "20px"]}
            mb="0.5"
          >
            About Seller
          </FormLabel>
          <Textarea
            bg="white"
            shadow="lg"
            type="text"
            color="#6B6162"
            fontSize="14px"
            fontWeight="400"
            {...register("bio")}
            onKeyDown={handleSpacebarPress}
          />
          <Text my={1} color="red" fontSize="xs">
            {errors.bio?.message}
          </Text> */}
          {/* <FormLabel
            color="#6B6162"
            fontSize="14px"
            fontWeight="400"
            alignSelf="flex-start"
            mt={["10px", "20px"]}
            mb="0.5"
          >
            Gallery
          </FormLabel>
          <Input
            bg="white"
            shadow="lg"
            multiple
            type="file"
            accept="image/*"
            color="#6B6162"
            fontSize="14px"
            fontWeight="400"
            placeholder="Choose file"
            onChange={handleImages}
            onKeyDown={handleSpacebarPress}
          />
          <Text my={1} color="red" fontSize="xs">
            {errors.gallery?.message}
          </Text> */}
          <Box>
            {/* <VStack mt="20px">
              <Grid templateColumns="repeat(4, 1fr)" gap={2}>
                {imageURLS.map((image) => (
                  <Box position={"relative"} w={20} h={20} shadow="lg">
                    <Image
                      src={image}
                      w={20}
                      h={20}
                      objectFit="cover"
                      rounded="lg"
                    />
                    <Box
                      position="absolute"
                      cursor="pointer"
                      bg="white"
                      top={0.5}
                      right={0.5}
                      rounded="full"
                      shadow="lg"
                      onClick={() => deleteImage(image)}
                    >
                      <GrFormClose fontSize={16} />
                    </Box>
                  </Box>
                ))}
              </Grid>
            </VStack> */}
          </Box>
          <FormLabel
            color="#6B6162"
            fontSize="14px"
            fontWeight="400"
            alignSelf="flex-start"
            mt={["10px", "20px"]}
            mb="0.5"
          >
            Category
          </FormLabel>
          <Select
            bg="white"
            shadow="lg"
            placeholder="Select category"
            type="text"
            color="#6B6162"
            fontSize="14px"
            fontWeight="400"
            {...register("category")}
            // onChange={handleSpacebarPress}
            onKeyDown={handleSpacebarPress}
            onChange={(e) => {
              const selectedCategory = JSON.parse(e.target.value);
              handleSubCategoryId(selectedCategory._id);
            }}
          >
            {categories?.length > 0 ?
              categories?.map((value) => (
                <option key={value?._id} value={JSON?.stringify(value)}>
                  {value?.mainCategory}
                </option>
              )) : (
                <option value="" disabled>
                  No category available
                </option>
              )}
          </Select>
          <Text my={1} color="red" fontSize="xs">
            {errors.category?.message}
          </Text>
          <FormLabel
            color="#6B6162"
            fontSize="14px"
            fontWeight="400"
            alignSelf="flex-start"
            mt={["10px", "20px"]}
            mb="0.5"
          >
          Sub Category
          </FormLabel>
          <Select
            bg="white"
            shadow="lg"
            placeholder="Select Sub Category"
            type="text"
            color="#6B6162"
            fontSize="14px"
            fontWeight="400"
            {...register("subCategory")}
            // onChange={handleSpacebarPress}
            onKeyDown={handleSpacebarPress}
          >
            {subCategories?.length > 0 ?
              subCategories?.map((value) => (
                <option key={value?._id} value={JSON?.stringify(value)}>
                  {value?.name}
                </option>
              ))
               : (
                <option value="" disabled>
                  No sub category available
                </option>
              )}
          </Select>
          <Text my={1} color="red" fontSize="xs">
            {errors.subCategory?.message}
          </Text>
          {/* <FormLabel
            color="#6B6162"
            fontSize="14px"
            fontWeight="400"
            alignSelf="flex-start"
            mt={["10px", "20px"]}
            mb="0.5"
          >
            Contact Details
          </FormLabel> */}
          <div className="mb-lg-2 mb-1 col-sm">
          <FormLabel
            color="#6B6162"
            fontSize="14px"
            fontWeight="400"
            alignSelf="flex-start"
            mt={["10px", "20px"]}
            mb="0.5"
          >
             Phone number
          </FormLabel>
                    {/* <label className="form-label ">
                      Phone number (optional)
                    </label> */}
                    <div className="input-group mb-1">
                      <span className="input-group-text" id="basic-addon3">
                        +44
                      </span>
                      <input
                        type="number"
                        className="form-control"
                        placeholder="Enter your phone number"
                        {...register("phone")}
                        onKeyDown={(e) => {
                          handleSpacebarPress(e)
                          if (!/[0-9]/.test(e.key) && e.key !== "Backspace" && e.key !== "ArrowLeft" && e.key !== "ArrowRight" && e.key !== "Tab") {
                            e.preventDefault();
                          }
                        }}
                        onWheel={(e) => e.target.blur()}
                      />
                    </div>
                      <Text my={1} color="red" fontSize="xs">
                        {errors.phone?.message}
                       </Text>
                   </div>
          {/* <Input   
            bg="white"
            shadow="lg"
            type="number"
            color="#6B6162"
            fontSize="14px"
            fontWeight="400"
            placeholder=""
            {...register("phone")}
            onKeyDown={handleSpacebarPress}
          />
          <Text my={1} color="red" fontSize="xs">
            {errors.phone?.message}
          </Text> */}
          {/* <Grid mt="30px">
            <GridItem colSpan={1} h="0.5" bg="gray.200" />
          </Grid>
          <HStack justifyContent="space-between" my={4}>
            <Text color="#6B6162" fontSize="14px" fontWeight="400">
              Opening Days
            </Text>
            <Checkbox
              onChange={(e) => changeCheckboxStatus(e, "p1")}
              isChecked={isParentChecked}
            >
              Select All Days
            </Checkbox>
          </HStack>
        
          <DaysTime
            days={users}
            changeCheckboxStatus={changeCheckboxStatus}
            handleStartTime={handleStartTime}
            handleEndTime={handleEndTime}
          /> */}

          <Button
            color="white"
            bg="#FF1A57"
            w="full"
            mt="20px"
            onClick={handleSubmit(handleSignup)}
            isLoading={isLoading}
          >
            Sign Up
          </Button>
          <Text>
          <div className="mt-2 ">
                  <p className="text-center">
                    By registering, you agree to  Primo offers's{" "} <br />
                    <Link to="/termsofservice" style={{color:"#FF1A57"}}>Terms of service </Link>
                    {/* <br />
                    Already have an account?  <a href="/login">Sign In</a> */}
                  </p>
                </div>
          </Text>
          <Text
            color="#FF1A57"
            fontSize="14px"
            my={2}
            textAlign="center"
            fontWeight="400"
            lineHeight="21px"
            cursor={"pointer"}
            onClick={() => navigate("/login")}
          >
            Back to Login
          </Text>
        </Box>
      </Box>
    </Box>
  );
};

export default Signup;
