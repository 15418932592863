import {
  Box,
  Heading,
  Text,
  Button,
  FormControl,
  FormLabel,
  Input,
  Checkbox,
  RadioGroup,
  Textarea,
  Select,
  Grid,
  GridItem,
  HStack,
} from "@chakra-ui/react";

import React, { useEffect } from "react";
import Layout from "../../components/Layout";
import { useDispatch, useSelector } from "react-redux";
import { createOffer, getCategory } from "../../api/user";
import { useForm } from "react-hook-form";
import { useState } from "react";
import ChangeImages from "../../components/ChangeImages";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup"
import Header from "../../components/Header";
import moment from "moment/moment";
import { DAYSDATA } from "../../constants";
import { offerValidator } from "../../helpers/validation";
import Navbar from "../../components/Navbar";
import RangeSlider from "react-rangeslider";
import "react-rangeslider/lib/index.css";

const NewOffer = () => {
  const { category,subCategory } = useSelector((state) => state?.authUser?.user);
  const dispatch = useDispatch();
  const [delivery, setDelivery] = useState([]);
  const [gallery, setGallery] = useState([]);
  const [categories, setCategories] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [subCategoryId, setSubCategoryId] = useState("");
  const [originalPrice, setOriginalPrice] = useState("");
  const [discountedPrice, setDiscountedPrice] = useState("");
  const [percentagePrice, setPercentagePrice] = useState("");
  const [isStudentDisable, setIsStudentDisable] = useState(true);
  const [isNHSDisable, setIsNHSDisable] = useState(true);
  const [checked, setChecked] = useState(null);
  const [studentPrice, setStudentPrice] = useState("");
  const [nhsPrice, setNhsPrice] = useState("");
  const [studentFinalPrice, setStudentFinalPrice] = useState();
  const [nhsFinalPrice, setNhsFinalPrice] = useState();
  const [selectedDays, setSelectedDays] = useState(DAYSDATA);
  const [isParentChecked, setIsParentChecked] = useState(false);
  const [checkedDays, setCheckedDays] = useState([]);
  const [imgError, setImgError] = useState("");
  const [priceError, setPriceError] = useState("");
  const [deliveryError, setDeliveryError] = useState("");
  const [popularityerror, setPopularityError] = useState("");
  const [range, setRange] = useState(0);

  const handleOnChange = (value) => {
    setRange(value);
  };

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(offerValidator),
    defaultValues: {deliveryCharges: ""}
  });

  const [minEndDate, setMinEndDate] = useState(
    moment(new Date()).format("YYYY-MM-DD")
  );
  const startDate = watch("startDate");
  React.useEffect(() => {
    if (startDate) {
      setMinEndDate(moment(startDate).format("YYYY-MM-DD"));
    }
  }, [startDate]);

  const toggle = (evt) => {
    setChecked((current) =>
      current === evt.target.value ? null : evt.target.value
    );
  };

  const handleStudent = (e, type) => {
    if (type === "student") {
      if (e) {
        setIsStudentDisable(false);
      } else {
        setIsStudentDisable(true);
        setStudentPrice("");
        setStudentFinalPrice("");
      }
    } else {
      if (e) {
        setIsNHSDisable(false);
      } else {
        setIsNHSDisable(true);
        setNhsPrice("");
        setNhsFinalPrice("");
      }
    }
  };
  const handleOriginalPrice = (e) => {
    const { value } = e.target;
    setOriginalPrice(value);
  };
  const handleDiscountedPrice = (e) => {
    const { value } = e.target;

    setDiscountedPrice(value);
  };
  const handlePercentagePrice = (e) => {
    const { value } = e.target;
    setPercentagePrice(value);
  };

  const handleKeyDown = (e, type) => {
    if (e.keyCode === 8 && type === "discount") {
      // setDiscountedPrice('')
    }
  };
  useEffect(() => {
    if (parseFloat(studentFinalPrice) > parseFloat(originalPrice)) {
      toast.error(
        "Student Effective price should not be greater than original price"
      );
      setStudentFinalPrice("");
      setStudentPrice("");
    }
  }, [studentFinalPrice, originalPrice]);

  useEffect(() => {
    if (studentPrice > 100) {
      toast.error("Student Percentage discount should not be greater than 100");
      setStudentPrice("");
      setStudentFinalPrice("");
    }
  }, [studentPrice, originalPrice]);

  useEffect(() => {
    if (parseFloat(nhsFinalPrice) > parseFloat(originalPrice)) {
      toast.error(
        "NHS Effective price should not be greater than original price"
      );
      setNhsFinalPrice("");
      setNhsPrice("");
    }
  }, [nhsFinalPrice, originalPrice]);

  useEffect(() => {
    if (nhsPrice > 100) {
      toast.error("NHS Percentage discount should not be greater than 100");

      setNhsPrice("");
      setNhsFinalPrice("");
    }
  }, [nhsPrice, originalPrice]);

  useEffect(() => {
    if (discountedPrice === "") {
      setPercentagePrice("");
    } else if (originalPrice === "" && discountedPrice !== "") {
      setPercentagePrice("");
    } else if (originalPrice === "" && percentagePrice !== "") {
      setDiscountedPrice("");
    } else if (originalPrice !== "" && discountedPrice === "") {
      setPercentagePrice("");
    } else if (parseFloat(discountedPrice) > parseFloat(originalPrice)) {
      toast.error("Discounted Price can't be greater than original price");
      setPercentagePrice("");
      setDiscountedPrice("");
    } else if (originalPrice !== "" && discountedPrice !== "") {
      let price = ((originalPrice - discountedPrice) / originalPrice) * 100;
      setPercentagePrice(price.toFixed(2));
    }
  }, [discountedPrice, originalPrice]);

  const handleDelivery = (e) => {

 
    if (e.target.checked) {
      setDelivery([...delivery, e.target.value]);
    } else {
      const filteredData = delivery.filter((item) => item !== e.target.value);
      setDelivery(filteredData);
    }
  };

  const navigate = useNavigate();

  useEffect(() => {
    dispatch(getCategory()).then((res) => {
      setCategories(res.data.categories);
    });
  }, []);

  const handleStudentPrice = (e) => {
    const discount = e.target.value;
    setStudentPrice(discount);
    if (originalPrice !== "" && discount !== "") {
      let sp = `${originalPrice - (discount / 100) * originalPrice}`;
      setStudentFinalPrice(parseFloat(sp).toFixed(2));
    } else {
      setStudentFinalPrice("");
    }
  };
  const handleNhsPrice = (e) => {
    const enterPrice = e.target.value;
    setNhsPrice(enterPrice);
    if (originalPrice !== "" && enterPrice !== "") {
      let np = `${originalPrice - (enterPrice / 100) * originalPrice}`;
      setNhsFinalPrice(parseFloat(np).toFixed(2));
    } else {
      setNhsFinalPrice("");
    }
  };

  const onSubmit = (data) => {
    if (gallery.length < 1) {
      setImgError("Add atleast one image.");
    } else if (originalPrice == "") {
      setPriceError("Original Price is required");
    } 
    else if (!checked) {
      setPopularityError("Popularity is required");
    } else {
      setImgError("");
      setPriceError("");
      // setDeliveryError("");
      setPopularityError("");
      const originalPri = parseFloat(originalPrice).toFixed(2);
      const discountedPri = parseFloat(discountedPrice).toFixed(2);
      const sp = parseFloat(studentFinalPrice).toFixed(2);
      const np = parseFloat(nhsFinalPrice).toFixed(2);
      const apiData = {
        offerName: data.offerName,
        quantity: data.quantity,
        category: {
          id: category.id,
          name: category.name,
        },
        isReducedPrice: false,
        commision: 10,
        ageRestrictedProduct: data.ageRestrictedProduct,
        address: "demo address",
        description: data.description,
        days: selectedDays,
        gallery,
        corporateDiscount: 0,
        cancellationPolicy: data.cancellationPolicy,
        validUntill: parseInt(data.validUntill),
        percentage: 10,
        specialDiscount: Number("50"),
        studentDiscount: Number(studentFinalPrice) || 0,
        studentPrice: Number(studentPrice) || 0,
        NHSDiscount: Number(nhsFinalPrice) || 0,
        NHSPrice: Number(nhsPrice) || 0,
        deliveryDistanceRange: range,
        deliveryCharges: Number(data.deliveryCharges),
        price: {
          orignalPrice: Number(originalPri),
          discountedPrice: Number(discountedPri),
          percentagePrice: Number(percentagePrice),
        },
        duration: {
          startDate: moment(data.startDate).format("DD/MM/YYYY"),
          endDate: moment(data.endDate).format("DD/MM/YYYY"),
        },
        delivery: delivery,
        terms: data.terms,
        popularity: checked,
      };
      apiData.varients = [];

      if (data.varientColor) {
        apiData.varients.push({
          key: "color",
          value: data.varientColor,
        });
      }

      if (data.varientSize) {
        apiData.varients.push({
          key: "size",
          value: data.varientSize,
        });
      }
      dispatch(createOffer(apiData))
        .then((res) => {
          if (res.statusCode === 200) {
            toast.success("Offer Created Successfully!");
            navigate("/manage-offers");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const changeCheckboxStatus = (e, id) => {
    const myUsers = [...selectedDays];
    const { checked } = e.target;
    myUsers.map((user) => {
      if (id === "allChecked") {
        setIsParentChecked(checked);
        user.isChecked = checked;
      } else {
        if (user.id === id) {
          user.isChecked = checked;
          if (checked) {
            setCheckedDays([...checkedDays, user]);
          } else {
            const removeUser = checkedDays.filter((item) => item !== user);
            setCheckedDays(removeUser);
          }
        }
        const isAllChildsChecked = myUsers.every(
          (user) => user.isChecked === true
        );
        if (isAllChildsChecked) {
          setIsParentChecked(checked);
        } else {
          setIsParentChecked(false);
        }
      }
      return user;
    });

    setSelectedDays([...myUsers]);
  };
  const handleSpacebarPress = (event) => {
    const { value } = event.target;
    if (event.key === ' ' && value.trim() === '') {
      alert('Spaces are not allowed in this field');
      event.preventDefault(); // Prevents the space character from being added to the input
    }
  }



  return (
    <>
      <Layout>
        <Box w={{ lg: "99%", md: "100%" }} mx={{ lg: "auto", md: "auto" }}>
          <Navbar />
          <ChangeImages gallery={gallery} setGallery={setGallery} />
          <Text fontSize="14px" color="red">
            {imgError}
          </Text>

          <Box
            w={{ xl: "61%", lg: "70%", md: "95%" }}
            p={1}
            mt={4}
            mx={{ lg: "0px", md: "auto" }}
          >
            <Box w={{ lg: "100%", md: "100%" }} mx={{ lg: "0px", md: "auto" }}>
              <Box>
                <Box w="100%">
                  <FormControl>
                    <FormLabel
                      fontSize="14px"
                      fontWeight="400"
                      color="#6B6162"
                      mb="0.5"
                    >
                      Offer name
                    </FormLabel>
                    <Input
                      w="100%"
                      fontSize="14px"
                      fontWeight="400"
                      color="#4B4447"
                      placeholder=""
                      shadow="lg"
                      {...register("offerName", { required: true })}
                      bg="white"
                    />
                    <Text my={1} color="red" fontSize="xs">
                      {errors.offerName?.message}
                    </Text>
                    <FormLabel
                      fontSize="14px"
                      fontWeight="400"
                      color="#6B6162"
                      mb="0.5"
                      mt={4}
                    >
                      {" "}
                      Category
                    </FormLabel>
                    <Input
                      w="100%"
                      fontSize="14px"
                      fontWeight="400"
                      color="#4B4447"
                      value={category?.name}
                      disabled
                      shadow="lg"
                      bg="white"
                      {...register("category", { required: true })}
                    >
                      {/*{categories.filter((value) => value._id(
                        <option key={value._id} value={JSON.stringify(value)}>
                          {value.mainCategory}
                        </option>
                      ))}
                     */}
                    </Input>
                    <Text my={1} color="red" fontSize="xs">
                      {errors.category?.message}
                    </Text>
                    <FormLabel
                      fontSize="14px"
                      fontWeight="400"
                      color="#6B6162"
                      mb="0.5"
                      mt={4}
                    >
                     Sub Category
                    </FormLabel>
                    <Input
                      w="100%"
                      fontSize="14px"
                      fontWeight="400"
                      color="#4B4447"
                      value={subCategory?.name}
                      disabled
                      shadow="lg"
                      bg="white"
                      {...register("subCategory", { required: true })}
                    >
                      {/*{categories.filter((value) => value._id(
                        <option key={value._id} value={JSON.stringify(value)}>
                          {value.mainCategory}
                        </option>
                      ))}
                     */}
                    </Input>
                    <Text my={1} color="red" fontSize="xs">
                      {errors.subCategory?.message}
                    </Text>
                  </FormControl>
                </Box>
                <Box display="flex" justifyContent="space-between">
                  <Box w="48%" mt={3}>
                    <FormControl>
                      <FormLabel
                        fontSize="14px"
                        fontWeight="400"
                        color="#6B6162"
                        mb="0.5"
                      >
                        Varient Size
                      </FormLabel>
                      <Input
                        fontSize="14px"
                        fontWeight="400"
                        color="#4B4447"
                        type="text"
                        shadow="lg"
                        bg="white"
                        {...register("varientSize", { required: true })}
                      />
                      {/*  <Text my={1} color="red" fontSize="xs">
                      {errors.varientSize?.message}
                    </Text>
                    */}
                    </FormControl>
                  </Box>
                  <Box w="48%" mt={3}>
                    <FormControl>
                      <FormLabel
                        fontSize="14px"
                        fontWeight="400"
                        color="#6B6162"
                        mb="0.5"
                      >
                        Varient Color
                      </FormLabel>
                      <Input
                        overflow={"hidden"}
                        fontSize="14px"
                        fontWeight="400"
                        color="#4B4447"
                        type="color"
                        shadow="lg"
                        p={0}
                        bg="white"
                        {...register("varientColor")}
                      />
                      <Text my={1} color="red" fontSize="xs">
                        {errors.varientColor?.message}
                      </Text>
                    </FormControl>
                  </Box>
                </Box>
              </Box>
              <Box
                w="100%"
                display={{ lg: "flex", md: "" }}
                justifyContent={{ lg: "space-between", md: "space-between" }}
                gap={6}
              >
                <Box
                  w={{ lg: "50%", md: "100%" }}
                  display="flex"
                  justifyContent="space-between"
                >
                  <Box w={{ lg: "46%", md: "50%" }} mt={3}>
                    <FormControl>
                      <FormLabel
                        fontSize="14px"
                        fontWeight="400"
                        color="#6B6162"
                      >
                        Quantity
                      </FormLabel>
                      <Input
                        fontSize="14px"
                        fontWeight="400"
                        color="#4B4447"
                        shadow="lg"
                        type="number"
                        bg="white"
                        {...register("quantity", { required: true })}
                        onKeyDown={(event) => {
                          if (event.key === ".") {
                            event.preventDefault();
                          }
                        }}
                        onInput={(event) => {
                          event.target.value = event.target.value.replace(
                            /[^0-9]*/g,
                            ""
                          );
                        }}
                      />
                    </FormControl>
                    <Text my={1} color="red" fontSize="xs">
                      {errors.quantity?.message}
                    </Text>
                  </Box>
                  <Box w="46%" mt={3}>
                    <FormControl>
                      <FormLabel
                        fontSize="14px"
                        fontWeight="400"
                        color="#6B6162"
                      >
                        Original Price
                      </FormLabel>
                      <Input
                        fontSize="14px"
                        fontWeight="400"
                        color="#4B4447"
                        shadow="lg"
                        type="number"
                        bg="white"
                        value={originalPrice}
                        onChange={handleOriginalPrice}
                        // {...register('orignalPrice', {required: true})}
                      />
                      <Text fontSize="14px" color="red">
                        {priceError}
                      </Text>
                    </FormControl>
                  </Box>
                </Box>
                <Box
                  w={{ lg: "50%", md: "100%" }}
                  display="flex"
                  justifyContent="space-between"
                >
                  <Box w={{ lg: "46%", md: "50%" }} mt={3}>
                    <FormControl>
                      <FormLabel
                        fontSize="14px"
                        fontWeight="400"
                        color="#6B6162"
                      >
                      New Discount Price
                      </FormLabel>
                      <Input
                        fontSize="14px"
                        fontWeight="400"
                        color="#4B4447"
                        shadow="lg"
                        bg="white"
                        type="number"
                        // isDisabled={isDiscountDisabled}
                        value={discountedPrice}
                        onChange={handleDiscountedPrice}
                        onKeyDown={(e) => handleKeyDown(e, "discount")}
                      />
                      <Text my={1} color="red" fontSize="xs">
                        {errors.discountedPrice?.message}
                      </Text>
                    </FormControl>
                  </Box>
                  <Box w="47%" mt={3}>
                    <FormControl>
                      <FormLabel
                        fontSize="14px"
                        fontWeight="400"
                        color="#6B6162"
                      >
                         Discount Percentage
                      </FormLabel>
                      <Input
                        fontSize="14px"
                        fontWeight="400"
                        color="#4B4447"
                        shadow="lg"
                        bg="white"
                        type="number"
                        min={1}
                        max={100}
                        cursor={"not-allowed"}
                        disabled
                        // isDisabled={isPercentageDisabled}
                        value={percentagePrice}
                        onChange={handlePercentagePrice}
                      />
                      <Text my={1} color="red" fontSize="xs">
                        {errors.percentagePrice?.message}
                      </Text>
                    </FormControl>
                  </Box>
                </Box>
              </Box>

              <Box display="flex" justifyContent="space-between">
                <Box w={{ lg: "49%", md: "49%" }} mt={3}>
                  <FormControl>
                    <FormLabel
                      fontSize="14px"
                      fontWeight="400"
                      color="#6B6162"
                      mb="0.5"
                    >
                      Start Date
                    </FormLabel>
                    <Input
                      fontSize="14px"
                      fontWeight="400"
                      color="#4B4447"
                      type="date"
                      min={moment(new Date()).format("YYYY-MM-DD")}
                      shadow="lg"
                      bg="white"
                      {...register("startDate", { required: true })}
                    />
                    <Text my={1} color="red" fontSize="xs">
                      {errors.startDate?.message}
                    </Text>
                  </FormControl>
                </Box>
                <Box w="48%" mt={3}>
                  <FormControl>
                    <FormLabel
                      fontSize="14px"
                      fontWeight="400"
                      color="#6B6162"
                      mb="0.5"
                    >
                      End Date
                    </FormLabel>
                    <Input
                      fontSize="14px"
                      fontWeight="400"
                      color="#4B4447"
                      type="date"
                      shadow="lg"
                      bg="white"
                      min={minEndDate}
                      {...register("endDate")}
                    />
                    <Text my={1} color="red" fontSize="xs">
                      {errors.endDate?.message}
                    </Text>
                  </FormControl>
                </Box>
              </Box>

              <Box
                display={{ lg: "flex", md: "flex" }}
                justifyContent="space-between"
              >
                <Box w="31%" mt={3}>
                  <FormControl>
                    <FormLabel
                      fontSize="14px"
                      fontWeight="400"
                      color="#6B6162"
                      mb="0.5"
                    >
                      Special Discounts(%)
                    </FormLabel>
                    <HStack>
                      <Checkbox
                        colorScheme="pink"
                        onChange={(e) =>
                          handleStudent(e.target.checked, "student")
                        }
                      >
                        <Text fontSize="14px" w="69px" h="21px">
                          Student
                        </Text>
                      </Checkbox>
                      <Input
                        type="number"
                        bg="white"
                        shadow="lg"
                        disabled={isStudentDisable}
                        value={studentPrice}
                        onChange={handleStudentPrice}
                        // {...register("studentDiscount")}
                      />
                    </HStack>
                    <HStack my={2}>
                      <Checkbox
                        colorScheme="pink"
                        onChange={(e) => handleStudent(e.target.checked, "nhs")}
                      >
                        <Text fontSize="14px" w="69px" h="21px">
                          NHS
                        </Text>
                      </Checkbox>
                      <Input
                        type="number"
                        isDisabled={isNHSDisable}
                        onChange={handleNhsPrice}
                        bg="white"
                        value={nhsPrice}
                        shadow="lg"
                        // {...register("NHSDiscount")}
                      />
                    </HStack>
                    <Text my={1} color="red" fontSize="xs">
                      {errors.specialDiscount?.message}
                    </Text>
                  </FormControl>
                </Box>

                <Box w="31%" mt={3}>
                  <FormControl>
                    <FormLabel
                      fontSize="14px"
                      fontWeight="400"
                      color="#6B6162"
                      mb="0.5"
                    >
                      Effective Price
                    </FormLabel>
                    <Input
                      type="text"
                      readOnly={true}
                      fontSize="14px"
                      color="#4B4447"
                      shadow="lg"
                      bg="white"
                      value={studentFinalPrice}
                      fontWeight="400"
                      cursor={"not-allowed"}
                      // disabled
                      // {...register("commision")}
                    ></Input>
                    <Text my={1} color="red" fontSize="xs">
                      {errors.commission?.message}
                    </Text>
                  </FormControl>
                  <FormControl>
                    <Input
                      type="text"
                      readOnly={true}
                      fontSize="14px"
                      color="#4B4447"
                      shadow="lg"
                      bg="white"
                      value={nhsFinalPrice}
                      fontWeight="400"
                      cursor={"not-allowed"}
                      // {...register("commision")}
                    ></Input>
                    <Text my={1} color="red" fontSize="xs">
                      {errors.commission?.message}
                    </Text>
                  </FormControl>
                </Box>
                <Box w="31%" mt={3}>
                  <FormControl>
                    <FormLabel
                      fontSize={{ lg: "14px", md: "13px" }}
                      fontWeight="400"
                      color="#6B6162"
                      mb="0.5"
                    >
                      Age restricted product?
                    </FormLabel>
                    <Select
                      placeholder="Select One"
                      fontSize="14px"
                      color="#4B4447"
                      shadow="lg"
                      bg="white"
                      fontWeight="400"
                      {...register("ageRestrictedProduct", { required: true })}
                    >
                      <option value={true}>Yes</option>
                      <option value={false}>No</option>
                    </Select>
                    <Text my={1} color="red" fontSize="xs">
                      {errors.ageRestrictedProduct?.message}
                    </Text>
                  </FormControl>
                </Box>
              </Box>
              <Box w={"100%"}>
                <FormControl>
                  <FormLabel
                    fontSize="14px"
                    fontWeight="400"
                    color="#6B6162"
                    my="4"
                  >
                    Distance Range
                  </FormLabel>
                  <RangeSlider
                    min={0}
                    max={30}
                    step={5}
                    labels={{
                      0: "0mi",
                      5: "5mi",
                      10: "10mi",
                      15: "15mi",
                      20: "20mi",
                      25: "25mi",
                      30: "30mi",
                    }}
                    value={range}
                    onChange={handleOnChange}
                  />
                  {/* <FormLabel
                    fontSize="14px"
                    fontWeight="400"
                    color="#6B6162"
                    mt="10"
                  >
                    Delivery Charges
                  </FormLabel>
                  <Input
                    w="100%"
                    fontSize="14px"
                    fontWeight="400"
                    color="#4B4447"
                    placeholder="Delivery Charges"
                    shadow="lg"
                    type="number"
                    {...register("deliveryCharges", { required: true })}
                    bg="white"
                  />
                  <Text my={1} color="red" fontSize="xs">
                    {errors.offerName?.message}
                  </Text> */}
                </FormControl>
              </Box>

              <Box display={{ lg: "flex" }} justifyContent="space-between">
                <Box w={{ lg: "36%", md: "100%" }} mt={3}>
                  <FormControl>
                    <FormLabel
                      fontSize="14px"
                      fontWeight="400"
                      color="#6B6162"
                      mb="0.5"
                    >
                      Cancellation Policy
                    </FormLabel>
                    <Textarea
                      fontSize="14px"
                      fontWeight="400"
                      color="#4B4447"
                      type="text"
                      shadow="lg"
                      bg="white"
                      {...register("cancellationPolicy")}
                    />
                    <Text my={1} color="red" fontSize="xs">
                      {errors.cancellationPolicy?.message}
                    </Text>
                  </FormControl>
                </Box>
                <Box w={{ lg: "62%", md: "100%" }} mt={3}>
                  <FormControl>
                    <FormLabel
                      fontSize="14px"
                      fontWeight="400"
                      color="#6B6162"
                      mb="0.5"
                    >
                      Cancellation validity from the date of purchase(In Days
                      Only)
                    </FormLabel>
                    <Input
                      fontSize="14px"
                      fontWeight="400"
                      type="number"
                      color="#4B4447"
                      shadow="lg"
                      bg="white"
                      h={20}
                      {...register("validUntill")}
                      onKeyDown={(event) => {
                        if (event.key === ".") {
                          event.preventDefault();
                        }
                      }}
                      onInput={(event) => {
                        event.target.value = event.target.value.replace(
                          /[^0-9]*/g,
                          ""
                        );
                      }}
                    />

                    <Text my={1} color="red" fontSize="xs">
                      {errors.validUntill?.message}
                    </Text>
                  </FormControl>
                </Box>
              </Box>
              <Box>
                <FormControl>
                  <FormLabel
                    fontSize="14px"
                    fontWeight="400"
                    color="#6B6162"
                    mb="0.5"
                  >
                    Terms & Conditions
                  </FormLabel>
                  <Textarea
                    fontSize="14px"
                    fontWeight="400"
                    color="#4B4447"
                    type="text"
                    shadow="lg"
                    bg="white"
                    {...register("terms")}
                  />
                  <Text my={1} color="red" fontSize="xs">
                    {errors.terms?.message}
                  </Text>
                </FormControl>
              </Box>
            </Box>

            <Box mt={5}>
              <Box>
                <Text fontSize="14px" color="#6B6162">
                  Days
                </Text>
              </Box>
              <Box w="100%" shadow="lg" bg="white" rounded="lg" p={2}>
                <Box w={{ lg: "67%", md: "70%" }} m={4}>
                  <Checkbox
                    onChange={(e) => changeCheckboxStatus(e, "allChecked")}
                    isChecked={isParentChecked}
                    colorScheme="pink"
                    // fontSize={{lg:"14px",md:"13px"}}
                  >
                    Select All Days
                  </Checkbox>
                  <Grid
                    templateRows="repeat(3, 1fr)"
                    templateColumns="repeat(3, 1fr)"
                    gap={10}
                  >
                    {DAYSDATA.map((day, index) => (
                      <GridItem
                        key={day.day}
                        colSpan={1}
                        w={{ lg: 160, md: 110 }}
                        display="flex"
                      >
                        <Box>
                          <Checkbox
                            isChecked={day?.isChecked}
                            colorScheme="pink"
                            value="child"
                            onChange={(e) =>
                              changeCheckboxStatus(e, index, day)
                            }
                          >
                            <Text
                              fontSize="14px"
                              color="#3B3538"
                              w="74px"
                              h="21px"
                            >
                              {day.day}
                            </Text>
                          </Checkbox>
                        </Box>
                      </GridItem>
                    ))}
                  </Grid>
                </Box>
              </Box>
            </Box>

            <Box mt={6} w="100%" shadow="lg" bg="white" p={2} rounded="lg">
              <Box mt={3} w="73%">
                <RadioGroup colorScheme="pink" p={1}>
                  <Box
                    m={5}
                    display="flex"
                    justifyContent={"space-between"}
                    w="full"
                  >
                    <Box w="30%">
                      <Checkbox
                        value="Dine-in"
                        onChange={handleDelivery}
                        colorScheme="pink"
                      >
                        <Text fontSize="14px" h="21px" color="#3B3538">
                          Dine-in
                        </Text>
                      </Checkbox>
                    </Box>
                    <Box w="30%">
                      <Checkbox
                        value="Take-away"
                        onChange={handleDelivery}
                        colorScheme="pink"
                      >
                        <Text fontSize="14px" h="21px" color="#3B3538">
                          Take-away
                        </Text>
                      </Checkbox>
                    </Box>
                    <Box w="30%">
                      <Checkbox
                        value="Delivery"
                        onChange={handleDelivery}
                        colorScheme="pink"
                      >
                        <Text fontSize="14px" h="21px" color="#3B3538">
                          Delivery
                        </Text>
                      </Checkbox>
                    </Box>
                  </Box>
                  <Box
                    m={5}
                    display="flex"
                    justifyContent={"space-between"}
                    w="full"
                  >
                    <Box w="30%">
                      <Checkbox
                        value="In-stores"
                        onChange={handleDelivery}
                        colorScheme="pink"
                      >
                        <Text fontSize="14px" color="#3B3538">
                          In-stores
                        </Text>
                      </Checkbox>
                    </Box>
                    <Box w="30%">
                      <Checkbox
                        value="Online"
                        onChange={handleDelivery}
                        colorScheme="pink"
                      >
                        <Text fontSize="14px" h="21px" color="#3B3538">
                          Online
                        </Text>
                      </Checkbox>
                    </Box>
                    <Box w="30%">
                      <Checkbox
                        value="Home Services"
                        onChange={handleDelivery}
                        colorScheme="pink"
                      >
                        <Text fontSize="14px" h="21px" color="#3B3538">
                          Home Services
                        </Text>
                      </Checkbox>
                    </Box>
                  </Box>
                  <Box
                    m={5}
                    display="flex"
                    justifyContent={"space-between"}
                    w="full"
                  >
                    <Box w="30%">
                      <Checkbox
                        value="Other"
                        // onChange={handleDelivery}
                        colorScheme="pink"
                      >
                        <Text fontSize="14px" color="#3B3538">
                          Other
                        </Text>
                      </Checkbox>
                    </Box>
                  </Box>
                </RadioGroup>
              </Box>
            </Box>

            {/* <Text fontSize="14px" color="red">
              {deliveryError}
            </Text> */}
            {(delivery.includes("Delivery") || delivery.includes("Online") || delivery.includes("Home Services")) && (
              <FormControl>
                <FormLabel
                  fontSize="14px"
                  fontWeight="400"
                  color="#6B6162"
                  mt="5"
                >
                  Delivery Charges
                </FormLabel>
                <Input
                  w="100%"
                  fontSize="14px"
                  fontWeight="400"
                  color="#4B4447"
                  placeholder="Delivery Charges"
                  shadow="lg"
                  type="text"
                  defaultValue={""}
                  {...register("deliveryCharges", {
                    required: "Delivery charges are required",
                    pattern: {
                      value: /^\d*(\.\d{0,2})?$/, // Allow numbers with up to two decimal places
                      message: "Please enter a valid amount with up to two decimal places"
                    },
                    validate: (value) => value > 0 || "Amount must be greater than zero", // Ensure positive values only
                  })}
                  bg="white"
                  onKeyDown={(e) => {
                    handleSpacebarPress(e)
                    if (!/[0.1-9.9]/.test(e.key) && e.key !== "Backspace" && e.key !== "ArrowLeft" && e.key !== "ArrowRight" && e.key !== "Tab") {
                      e.preventDefault();
                    }
                  }}
                  onInput={(e) => {
                    // Trim input to limit two digits after decimal
                    if (e.target.value.includes(".")) {
                      const [integer, decimal] = e.target.value.split(".");
                      if (decimal && decimal.length > 2) {
                        e.target.value = `${integer}.${decimal.substring(0, 2)}`;
                      }
                    }
                    // Show empty string for zero value
                    if (e.target.value === "0" || e.target.value === 0) {
                      e.target.value = "";
                    }
                  }}
                  onWheel={(e) => e.target.blur()}
                />
                  <p style={{fontSize: "13px", color: "red", marginTop: "2px"}}>{errors.deliveryCharges?.message}</p>
                {/* <Text my={1} color="red" fontSize="xs">
                 {deliveryError}
                </Text> */}
              </FormControl>
            )}

            <Box
              mt={3}
              w="100%"
              shadow="lg"
              bg="white"
              p={2}
              rounded="lg"
              {...register("popularity", { required: true })}
            >
              <Box mt={3} w="71%">
                <Box
                  m={5}
                  colorScheme="pink"
                  display="flex"
                  justifyContent="space-between"
                >
                  <Box>
                    <Checkbox
                      colorScheme="pink"
                      value="peak"
                      isChecked={checked == "peak"}
                      onChange={toggle}
                    >
                      <Text fontSize="14px" w="69px" h="21px">
                        Peak
                      </Text>
                    </Checkbox>
                  </Box>
                  <Box>
                    <Checkbox
                      colorScheme="pink"
                      value="offPeak"
                      isChecked={checked == "offPeak"}
                      onChange={toggle}
                    >
                      <Text fontSize="14px" w="69px" h="21px">
                        Off-Peak
                      </Text>
                    </Checkbox>
                  </Box>
                  <Box>
                    <Checkbox
                      colorScheme="pink"
                      value="anyTime"
                      isChecked={checked == "anyTime"}
                      onChange={toggle}
                    >
                      <Text fontSize="14px" w="69px" h="21px">
                        Any Time
                      </Text>
                    </Checkbox>
                  </Box>
                </Box>
              </Box>
            </Box>
            <Text fontSize="14px" color="red">
              {popularityerror}
            </Text>
            <Box>
              <Box mt={5}>
                <Text fontSize="14px" w="113px" h="21px" color="#6B6162">
                  Offer description{" "}
                </Text>
                <Textarea
                  shadow="lg"
                  bg="white"
                  placeholder=""
                  fontSize="14px"
                  color="#4B4447"
                  {...register("description", { required: true })}
                />
                <Text my={1} color="red" fontSize="xs">
                  {errors.description?.message}
                </Text>
              </Box>
              <Box w="full" align="center" mt={8}>
                <Box mt={2}>
                  <Button
                    w="100%"
                    bg="#FF1A57"
                    color="white"
                    fontSize="14px"
                    fontFamily="Commissioner"
                    onClick={handleSubmit(onSubmit)}
                  >
                    Save
                  </Button>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Layout>
    </>
  );
};

export default NewOffer;
