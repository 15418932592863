import { Box, Button, Image, Text, VStack } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { forgotPassword, otpVerification } from "../../api/user";
import { useLocation, useNavigate } from "react-router-dom";
import { frame, updatedLogo } from "../../assets/icons";
import OtpInput from "react-otp-input";
import { toast } from "react-toastify";

const Index = () => {
  const navigate = useNavigate();
  const [otp, setOtp] = useState("");
  const [timer, setTimer] = useState(30);
  const [resendDisabled, setResendDisabled] = useState(true);
  const handleChange = (otp) => setOtp(otp);
  const { state } = useLocation();
  const { email } = state;
  const dispatch = useDispatch();

  useEffect(() => {
    if (!resendDisabled) {
      return;
    }
    const timeout = setTimeout(() => {
      setResendDisabled(false);
      setTimer(30);
    }, 30000);
    const timerInterval = setInterval(() => {
      setTimer((prevTimer) => prevTimer - 1);
    }, 1000);
    if (timer <= 0) {
      setResendDisabled(false);
    }
    return () => {
      clearTimeout(timeout);
      clearInterval(timerInterval);
    };
  }, [resendDisabled, timer]);

  const handleResendClick = () => {
    setResendDisabled(true);
    setTimer(30);
    dispatch(forgotPassword({ email })).then((res) => {
      if (res.statusCode === 200) {
        toast.success(res.data.otp);
      } else {
        toast.error("Error sending OTP. Please try again.");
      }
    });
  };

  const handleSubmit = () => {
    const apiData = {
      email: state.email,
      otp,
    };
    dispatch(otpVerification(apiData))
      .then((res) => {
        if (res.statusCode === 200) {
          toast.success("OTP verified successfully!");
          navigate("/reset-password", {
            state: {
              email: state.email,
              token: res.data.token,
            },
          });
        } else {
          toast.error(res.message);
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error(err.data.message);
      });
  };
  return (
    <Box display={"grid"} placeItems={"center"} h={"100vh"}>
      <Box w="80%" display={"grid"} placeItems={"center"} gap="40px">
        <Box>
          <VStack>
            <Image src={updatedLogo} />
            <Text fontSize="xs" ml={""}>
              Seller Dashboard V1.0
            </Text>
          </VStack>
          <Text
            textAlign={"center"}
            fontSize="20px"
            color="#3B3538"
            fontWeight="600"
            mt="20px"
          >
            Verification code
          </Text>
          <Text
            textAlign={"center"}
            fontSize="16px"
            fontWeight="400"
            mt="20px"
            color="#3B3538"
          >
            Please enter the verification code sent on <br />({state?.email})
          </Text>
        </Box>
        <Box w="20%" justifyContent="space-between">
          <OtpInput
            value={otp}
            onChange={handleChange}
            numInputs={4}
            placeholder=""
            containerStyle={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
            }}
            inputStyle={{
              backgroundColor: "white",
              border: "1px solid gray",
              fontWeight: "600",
              fontSize: "34px",
              borderRadius: 4,
              width: 30,
              height: 50,
            }}
          />
          <Box align="center" mt="5">
            <Text color="#3B3538" fontSize="14px" fontWeight="400">
              No code?
              <span
                style={{ cursor: resendDisabled ? "wait" : "pointer" }}
                onClick={handleResendClick}
                disabled={resendDisabled}
              >
                send again
              </span>
              {resendDisabled && <span> - ({timer}s)</span>}{" "}
            </Text>
          </Box>
        </Box>
        <Box w="80%" align="center">
          <Button color="white" bg="#FF1A57" onClick={handleSubmit} w="40%">
            Continue
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default Index;
