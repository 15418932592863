import {
  Box,
  Button,
  FormLabel,
  Image,
  Input,
  Text,
  VStack,
} from "@chakra-ui/react";
import React from "react";
import { frame, updatedLogo } from "../../assets/icons";
import { forgotPassword } from "../../api/user";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";

const Index = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onSubmit = (data) => {
    const { email } = data;
    dispatch(forgotPassword({ email }))
      .then((res) => {
        if (res.statusCode === 200) {
          toast.success(res.data.otp);
          navigate("/otp-verification", {
            state: {
              email,
              otp: res.data.otp,
            },
          });
        } else {
          
        }
      })
      .catch((err) => {
        toast.error(err.data.message);
      });
  };
  return (
    <Box
      display={"grid"}
      placeItems={"center"}
      h={"100vh"}
      flexDirection={["column", "row"]}
    >
      <Box
        w={["100%", "60%"]}
        display={"grid"}
        placeItems={"center"}
        gap="40px"
        flexDirection={["column", "row"]}
      >
        <Box>
          <VStack>
            <Image src={updatedLogo} />
            <Text fontSize="xs" ml={""}>
              Seller Dashboard V1.0
            </Text>
          </VStack>
          <Text
            textAlign={"center"}
            fontSize="23px"
            color="#3B3538"
            fontWeight="600"
            mt="20px"
            lineHeight="28px"
          >
            Forgot Password?
          </Text>
        </Box>
        <Box w={["75%", "40%"]}>
          <FormLabel
            alignSelf={"flex-start"}
            color="#6B6162"
            fontSize="14px"
            fontWeight="400"
            lineHeight="21px"
          >
            Email
          </FormLabel>
          <VStack gap="20px">
            <Box w="100%">
              <Input
                bg="white"
                shadow="lg"
                placeholder="Email@gmail.com"
                border="1px"
                borderColor="#B1ABA9"
                fontSize="14px"
                fontWeight="400"
                lineHeight="21px"
                {...register("email", { required: true })}
              />
              {errors?.email && (
                <Text my={1} color="red" fontSize="xs">
                  Email is required
                </Text>
              )}
            </Box>

            <Box w="100%">
              <Button
                w={"full"}
                bg="#FF1A57"
                color="white"
                fontSize="14px"
                fontWeight="600"
                lineHeight="21px"
                onClick={handleSubmit(onSubmit)}
              >
                Submit
              </Button>
              <Text
                color="#FF1A57"
                fontSize="14px"
                my={2}
                textAlign="center"
                fontWeight="400"
                lineHeight="21px"
                cursor={"pointer"}
                onClick={() => navigate("/login")}
              >
                Back to Login
              </Text>
            </Box>
          </VStack>
        </Box>
      </Box>
    </Box>
  );
};

export default Index;
