import React, { useEffect } from 'react';
import "./terms.css";
import { Link } from "react-router-dom";
import { Image, Text } from '@chakra-ui/react';
import { updatedLogo } from '../../assets/icons';

const TermsOfService = () => {
  useEffect(() => {
    // Scroll to the top of the page
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
    <div class="faq_container p-4">
        <div style={{display:"flex",justifyContent:"center",alignItems:"center",direction:"-moz-initial"}}>
            <div>
                <div>
                <Image src={updatedLogo} />
                </div>
             <Text style={{fontSize:"12px"}}>Seller Dashboard V1.0</Text>
            </div>
        </div>
  
        <h1 class="text_dark  mb-5">
          <b> TERMS AND CONDITIONS OF USE</b>
        </h1>
        <h4>
          <strong>Introduction</strong>
        </h4>
        <p>
          These terms and conditions apply between you, the User of this Website
          (including any sub-domains, unless expressly excluded by their own
          terms and conditions), and PRIMOOFFERS LIMITED, the owner and operator
          of this Website. Please read these terms and conditions carefully, as
          they affect your legal rights. Your agreement to comply with and be
          bound by these terms and conditions is deemed to occur upon your first
          use of the Website. If you do not agree to be bound by these terms and
          conditions, you should stop using the Website immediately.
        </p>
        <p>
          In these terms and conditions, <strong>User</strong> or{" "}
          <strong>Users</strong> means any third party that accesses the Website
          and is not either (i) employed by PRIMOOFFERS LIMITED and acting in
          the course of their employment or (ii) engaged as a consultant or
          otherwise providing services to PRIMOOFFERS LIMITED and accessing the
          Website in connection with the provision of such services.
        </p>
        <p>
          You must be at least 18 years of age to use this Website. By using the
          Website and agreeing to these terms and conditions, you represent and
          warrant that you are at least 18 years of age.
        </p>
        <h4>
          <strong>Intellectual property and acceptable use</strong>
        </h4>
        <p class="list_item">
          All Content included on the Website, unless uploaded by Users, is the
          property of PRIMOOFFERS LIMITED, our affiliates or other relevant
          third parties. In these terms and conditions, Content means any text,
          graphics, images, audio, video, software, data compilations, page
          layout, underlying code and software and any other form of information
          capable of being stored in a computer that appears on or forms part of
          this Website, including any such content uploaded by Users. By
          continuing to use the Website you acknowledge that such Content is
          protected by copyright, trademarks, database rights and other
          intellectual property rights. Nothing on this site shall be construed
          as granting, by implication, estoppel, or otherwise, any license or
          right to use any trademark, logo or service mark displayed on the site
          without the owner's prior written permission
        </p>
        <p class="list_item">
          You may, for your own personal, non-commercial use only, do the
          following:
        </p>
        <ul class="list_style ul_spc">
          <li>retrieve, display and view the Content on a computer screen</li>
          <li>
            download and store the Content in electronic form on a disk (but not
            on any server or other storage device connected to a network)
          </li>
          <li>print one copy of the Content</li>
        </ul>
        <p class="list_item">
          You must not otherwise reproduce, modify, copy, distribute or use for
          commercial purposes any Content without the written permission of
          PRIMOOFFERS LIMITED.
        </p>
        <h4>
          <strong>Prohibited use</strong>
        </h4>
        <p class="list_item">
          You may not use the Website for any of the following purposes:
        </p>
        <ul class="list_style ul_spc">
          <li>
            in any way which causes, or may cause, damage to the Website or
            interferes with any other person's use or enjoyment of the Website;
          </li>
          <li>
            in any way which is harmful, unlawful, illegal, abusive, harassing,
            threatening or otherwise objectionable or in breach of any
            applicable law, regulation, governmental order;
          </li>
          <li>
            making, transmitting or storing electronic copies of Content
            protected by copyright without the permission of the owner.
          </li>
        </ul>
        <h4>
          <strong>Registration</strong>
        </h4>
        <p class="list_item">
          You must ensure that the details provided by you on registration or at
          any time are correct and complete.
        </p>
        <p class="list_item">
          You must inform us immediately of any changes to the information that
          you provide when registering by updating your personal details to
          ensure we can communicate with you effectively.
        </p>
        <p class="list_item">
          We may suspend or cancel your registration with immediate effect for
          any reasonable purposes or if you breach these terms and conditions.{" "}
        </p>
        <p class="list_item">
          You may cancel your registration at any time by informing us in
          writing to the address at the end of these terms and conditions. If
          you do so, you must immediately stop using the Website. Cancellation
          or suspension of your registration does not affect any statutory
          rights.
        </p>
        <h4>
          <strong>Password and security</strong>
        </h4>
        <p class="list_item">
          When you register on this Website, you will be asked to create a
          password, which you should keep confidential and not disclose or share
          with anyone.
        </p>
        <p class="list_item">
          If we have reason to believe that there is or is likely to be any
          misuse of the Website or breach of security, we may require you to
          change your password or suspend your account.{" "}
        </p>
        <h4>
          <strong>Links to other websites</strong>
        </h4>
        <p class="list_item">
          This Website may contain links to other sites. Unless expressly
          stated, these sites are not under the control of PRIMOOFFERS LIMITED  or that
          of our affiliates.
        </p>
        <p class="list_item">
          We assume no responsibility for the content of such Websites and
          disclaim liability for any and all forms of loss or damage arising out
          of the use of them.
        </p>
        <p class="list_item">
          The inclusion of a link to another site on this Website does not imply
          any endorsement of the sites themselves or of those in control of
          them.
        </p>
        <h4>
          <strong>Privacy Policy and Cookies Policy</strong>
        </h4>
        <p class="list_item">
          Use of the Website is also governed by our Privacy Policy and Cookies
          Policy, which are incorporated into these terms and conditions by this
          reference. To view the Privacy Policy and Cookies Policy, please click
          on the following:
          <Link to="/privacypolicy" target="_blank" style={{color:"#FF1A57"}}>
          https://www.merchants.primooffers.com/privacy-policy/
          </Link>{" "}
          and {""}
          <Link to="/cookiespolicy" target="_blank" style={{color:"#FF1A57"}}>
          https://www.merchants.primooffers.com/cookies-policy/
          </Link>.
        </p>
        <h4>
          <strong>Availability of the Website and disclaimers</strong>
        </h4>
        <p class="list_item">
          Any online facilities, tools, services or information that PRIMOOFFERS LIMITED
          makes available through the Website (the Service) is provided "as is"
          and on an "as available" basis. We give no warranty that the Service
          will be free of defects and/or faults. To the maximum extent permitted
          by the law, we provide no warranties (express or implied) of fitness
          for a particular purpose, accuracy of information, compatibility and
          satisfactory quality. PRIMOOFFERS LIMITED is under no obligation to update
          information on the Website.
        </p>
        <p class="list_item">
          Whilst PRIMOOFFERS LIMITED uses reasonable endeavours to ensure that the
          Website is secure and free of errors, viruses and other malware, we
          give no warranty or guaranty in that regard and all Users take
          responsibility for their own security, that of their personal details
          and their computers.
        </p>
        <p class="list_item">
          PRIMOOFFERS LIMITED accepts no liability for any disruption or
          non-availability of the Website.
        </p>
        <p class="list_item">
          PRIMOOFFERS LIMITED reserves the right to alter, suspend or discontinue any
          part (or the whole of) the Website including, but not limited to, any
          products and/or services available. These terms and conditions shall
          continue to apply to any modified version of the Website unless it is
          expressly stated otherwise.
        </p>
        <h4>
          <strong>Limitation of liability</strong>
        </h4>
        <p class="list_item">
          Nothing in these terms and conditions will: (a) limit or exclude our
          or your liability for death or personal injury resulting from our or
          your negligence, as applicable; (b) limit or exclude our or your
          liability for fraud or fraudulent misrepresentation; or (c) limit or
          exclude any of our or your liabilities in any way that is not
          permitted under applicable law.
        </p>
        <p class="list_item">
          We will not be liable to you in respect of any losses arising out of
          events beyond our reasonable control.
        </p>
        <p class="list_item">
          To the maximum extent permitted by law, PRIMOOFFERS LIMITED accepts no
          liability for any of the following:
        </p>
        <ul class="list_style ul_spc">
          <li>
            any business losses, such as loss of profits, income, revenue,
            anticipated savings, business, contracts, goodwill or commercial
            opportunities;
          </li>
          <li>loss or corruption of any data, database or software;</li>
          <li> any special, indirect or consequential loss or damage.</li>
        </ul>
        <h4>
          <strong>General</strong>
        </h4>
        <p class="list_item">
          You may not transfer any of your rights under these terms and
          conditions to any other person. We may transfer our rights under these
          terms and conditions where we reasonably believe your rights will not
          be affected.
        </p>
        <p class="list_item">
          These terms and conditions may be varied by us from time to time. Such
          revised terms will apply to the Website from the date of publication.
          Users should check the terms and conditions regularly to ensure
          familiarity with the then current version.{" "}
        </p>
        <p class="list_item">
          These terms and conditions together with the Privacy Policy and
          Cookies Policy contain the whole agreement between the parties
          relating to its subject matter and supersede all prior discussions,
          arrangements or agreements that might have taken place in relation to
          the terms and conditions.
        </p>
        <p class="list_item">
          The Contracts (Rights of Third Parties) Act 1999 shall not apply to
          these terms and conditions and no third party will have any right to
          enforce or rely on any provision of these terms and conditions.
        </p>
        <p class="list_item">
          If any court or competent authority finds that any provision of these
          terms and conditions (or part of any provision) is invalid, illegal or
          unenforceable, that provision or part-provision will, to the extent
          required, be deemed to be deleted, and the validity and enforceability
          of the other provisions of these terms and conditions will not be
          affected.
        </p>
        <p class="list_item">
          Unless otherwise agreed, no delay, act or omission by a party in
          exercising any right or remedy will be deemed a waiver of that, or any
          other, right or remedy.
        </p>
        <p class="list_item">
          This Agreement shall be governed by and interpreted according to the
          law of England and Wales and all disputes arising under the Agreement
          (including non-contractual disputes or claims) shall be subject to the
          exclusive jurisdiction of the English and Welsh courts.
        </p>
        <h4>
          <strong>PRIMOOFFERS LIMITED details</strong>
        </h4>
        <p class="list_item">
          PRIMOOFFERS LIMITED is a company incorporated in England and Wales with
          registered number 15835192 whose registered address is 20-22 WENLOCK ROAD, LONDON, N1 7GU 
          and it operates the Website{" "}
          <Link to="/login" target="_blank" style={{color:"#FF1A57"}}>
          https://www.merchants.primooffers.com/
          </Link>.
          You can contact https://www.merchants.primooffers.com/ by email on ask@primooffers.com.
        </p>
      </div>
    </>
  )
}

export default TermsOfService